import { Button, message } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { Maybe } from "../../interfaces/Utils";
import { Callbackize } from "../../lib/functions";
import { getStringFromParams } from "../../lib/get_params";
import { InfoAction, InfoState, INFO_SET_STEP } from "../../store/infographics";
import { IndicatorSpec } from "../Indicators/interface";

//#region  Store connexion
interface StateProps {
	current: number;
	props: Maybe<IndicatorSpec>;
	type: Maybe<string>;
}
function mapStateToProps(state: { info: InfoState }): StateProps {
	const {
		info: {
			step,
			props,
			desc: { type },
		},
	} = state;
	return {
		current: step ?? 0,
		props: props,
		type: type,
	};
}

interface DispatchProps {
	setStep: (step: number) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setStep: function setStep(step: number) {
			dispatch({ type: INFO_SET_STEP, payload: { step: step } } as InfoAction);
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);

//#endregion

interface StepButtonProps extends ConnectedProps<typeof connector> {
	steps: number;
	onFinish: Maybe<() => void>;
}

function CStepButton(props: StepButtonProps) {
	const { current, steps, setStep, onFinish, props: indic, type } = props;
	const prev = Callbackize(setStep, current - 1);
	const next = Callbackize(setStep, current + 1);

	const mProjectId = getStringFromParams("projectId");

	const elements: JSX.Element[] = [];

	if (current !== steps - 1) {
		elements.push(
			<Button size="large" type="link" onClick={onFinish}>
				{!mProjectId ? "Enregistrer brouillon" : "Sauvegarder"}
			</Button>
		);
	}
	if (current === 0) {
		elements.push(
			<Button
				size="large"
				style={{ margin: "0 8px" }}
				href={mProjectId ? `/visualisations/projects/edit/${mProjectId}` : "/"}
			>
				Retour au projet
			</Button>
		);
	} else {
		elements.push(
			<Button size="large" style={{ margin: "0 8px" }} onClick={prev}>
				Précédent
			</Button>
		);
	}
	if (current === steps - 1) {
		elements.push(
			<Button
				size="large"
				type="primary"
				onClick={onFinish ?? (() => message.success("good"))}
			>
				Sauvegarder la vue
			</Button>
		);
	} else {
		const disabled =
			false ||
			(current === 1 && indic === undefined) ||
			(current === 0 && type === undefined);
		elements.push(
			<Button size="large" type="primary" onClick={next} disabled={disabled}>
				Suivant
			</Button>
		);
	}

	return <>{elements}</>;
}

export default connector(CStepButton);
