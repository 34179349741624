import { useState, useEffect, MutableRefObject } from "react";

import { Button, message } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import _ from "lodash";
import moment from "moment";

import ExportCSV from "./ExportCSV";

import { Map, RDSA, RSetter } from "../../../../interfaces/Utils";
import { EditState } from "../interface";

function validateDataType(s: string, data_type: string): [any, boolean] {
    if (s.length === 0) { return [undefined, true] }
    switch (data_type) {
        case "complete":
        case "keyword":
        case "string":
            return [s, true];
        case "integer": {
            return isNaN(+s)
                ? [undefined, false]
                : [parseInt(s), true];
        }
        case "float": {
            return isNaN(+s)
                ? [undefined, false]
                : [parseFloat(s), true];
        }
        case "date": {
            if (moment.isMoment(s)) {
                return [s, true];
            }
            const m = moment(s, ["YYYY/MM/DD", "DD/MM/YYYY"], true);
            return [m, m.isValid()];
        }
        default: return [s, true];
    }
}

interface HerePasteProps {
    headers: { title: string, dataIndex: string }[];
    keys: Map<{ data_type: string, title: string }>;
    setVisibleRef: MutableRefObject<RDSA<boolean>>;
    editState: MutableRefObject<[EditState, RSetter<EditState>]>;
    arrays: Map<string>;
    requiredValues: string[];
    title: string;
}

function HerePaste(props: HerePasteProps) {
    const [open, setOpen] = useState<boolean>(false);
    const {
        keys, setVisibleRef, requiredValues, title,
        editState: { current: [state, setState] }, arrays: arraysMap,
    } = props;
    const arrays = Object.keys(arraysMap).map(k => arraysMap[k]);


    function paste(s: string) {
        const text = s;
        const lines = text.split("\r\n");
        if (lines.length === 0) {
            message.warning("aucune donnée à ajouter.");
            return;
        }
        const table = lines.map(line => line.split("\t"));
        const headerRow = table.splice(0, 1)[0];
        if (table.length === 0) {
            message.warning(`Il semblerait qu'il n'y a aucune ligne à ajouter.
Veuillez vérifier que vous coller aussi bien les lignes que les titres des colonnes`);
            return;
        }
        const ks = Object.keys(keys).reduce((acc, cur) => {
            const e = keys[cur];
            return ({
                ...acc,
                [e.title]: {
                    data_type: e.data_type,
                    key: cur,
                },
            });
        }, {} as Map<{ data_type: string; key: string; }>);
        const datum: any[] = [];
        for (let j = 0; j < table.length; j++) {
            const line = table[j];
            const o: Map<any> = {};
            for (let i = 0; i < headerRow.length; i++) {
                const h = headerRow[i];
                const v = line[i];
                const e = ks[h];
                const rk = e.key;
                const required = requiredValues.findIndex(s => rk === s) !== -1;
                let [value, valid] = validateDataType(v, e.data_type);
                if (!valid) {
                    message.error(`ligne ${j + 1}: donnée invalide pour ${h}, donnée exclue de la ligne`);
                    continue;
                } else if (value === undefined && required) {
                    message.error(`ligne ${j + 1}: donnée manquante pour ${h}, donnée exclue de la ligne`);
                    continue;
                }
                if (arrays.findIndex(s => s === rk) !== -1) {
                    value = [value];
                }
                if (rk.includes(".")) {
                    const [l, r] = rk.split(".");
                    const e = o[l];
                    if (e !== undefined) {
                        e[r] = value;
                    } else {
                        o[l] = { [r]: value };
                    }
                } else {
                    o[rk] = value;
                }
            }
            o.key = _.uniqueId("pasted-data-");
            datum.push(o);
        }
        message.info(`Nous avons ajouté ${table.length} lignes à votre tableau.`);
        setVisibleRef.current?.(false);
        const newEditedData = [...datum, ...state.editedData];
        setState({ ...state, editedData: newEditedData });
    }

    useEffect(() => {
        function handler(e: Event) {
            const data = (e as ClipboardEvent)?.clipboardData?.getData('text');
            if (!data) {
                console.log("no data");
                return;
            }
            paste(data);
        }

        window.addEventListener('paste', handler);
        return () => window.removeEventListener('paste', handler);
    }, []);

    return (
        <div>
            <Button onClick={() => {
                navigator.clipboard.readText().then(paste)
            }}>
                Coller depuis le presse-papier
            </Button>
            <div>Ctrl+v votre sélection depuis Excel</div>
            <div>(cmd+v sous MacOs)</div>
            <div>
                <div>
                    Clefs possibles (l'ordre n'a pas d'importance) :
                    <span
                        style={{ marginLeft: 10 }}
                        onClick={() => setOpen(!open)}
                    >
                        {open
                            ? <MinusCircleOutlined />
                            : <PlusCircleOutlined />
                        }
                    </span>
                </div>
                <ul>
                    {open
                        ? Object.keys(keys).map(h => <li>{keys[h].title}</li>)
                        : null
                    }
                </ul>
            </div>
            <ExportCSV title={title} />
        </div>
    );
}

export default HerePaste;
