import { useState } from "react";
import { Input, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FilterDropdownProps as _FilterDropdownProps } from "antd/lib/table/interface";
import { Suggestions } from "../../../../model/vue";
import Search, { defaultPagination, SearchParams } from "../../../../model/search";
import { paginationLens, paramsLens } from "../../lenses";
import TagRenderer from "../../SuggTag";
import getDomainColor from "../../../tools/getDomainColor";

interface FilterDropdownProps extends _FilterDropdownProps {
	e: any;
	search: Search;
	updateSearch(s: Search): void;
	suggestions: Suggestions;
	updateSuggestions(index: string, key: string): void;
	lang?: string
}

function FilterDropdown(props: FilterDropdownProps) {
	const { e, search, updateSearch, suggestions, updateSuggestions, lang } = props;
	const params: SearchParams = paramsLens.get(search);
	const index: string = e?._dataIndex ?? "";

	// Selected keys
	const selectedKeys = paramsLens.get(search)[index] ?? [];
	const setSelectedKeys: (keys: string[]) => void = (keys) => {
		const s1 = paramsLens.set(search, { ...params, [index]: keys });
		const s2 = paginationLens.set(s1, defaultPagination);
		updateSearch(s2);
	};

	const clearFilters = () => setSelectedKeys([]);

	const [buf, setBuf] = useState("");

	const remove = (i: number) => () => {
		const keys: React.Key[] = [];
		for (let j = 0; j < selectedKeys.length; j++) {
			if (j === i) {
				continue;
			}
			keys.push(selectedKeys[j]);
		}
		setSelectedKeys(keys.map((e) => `${e}`));
	};

	const tagWrapper = (k: any, i: number) => (
		<Tag
			style={{ cursor: "pointer" }}
			color={getDomainColor(true)}
			onClick={remove(i)}
		>
			{k}
		</Tag>
	);

	const updateKeys = (v: string) => {
		setBuf("");
		const s1 = paramsLens.set(search, { ...params, [index]: [...selectedKeys, v] });
		const s2 = paginationLens.set(s1, defaultPagination);
		updateSearch(s2);
		updateSuggestions("", "");
	};

	const onPressEnter = () => {
		if (!buf || (selectedKeys ?? []).findIndex((e) => e === buf) !== -1) return;
		updateKeys(buf);
	};

	const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		updateSuggestions(index, value);
		setBuf(value);
	};

	const inputStyle = { width: 250, marginBottom: 5, marginRight: 5, display: "block" };
	const searchIn = lang === 'en' ? "Search in" : "Rechercher dans";
	return (
		<div style={{ padding: 8 }}>
			<div
				style={{
					display: "flex",
					alignContent: "center",
					alignItems: "baseline",
				}}
			>
				<Input
					placeholder={`${searchIn} '${e.title}'`}
					value={buf}
					onChange={onChange}
					onPressEnter={onPressEnter}
					style={inputStyle}
				/>
				{!!selectedKeys && selectedKeys.length > 0 ? (
					<CloseOutlined
						color="red"
						style={{ cursor: "pointer", color: "darkred" }}
						onClick={clearFilters}
					/>
				) : null}
			</div>
			<TagRenderer
				index={index}
				suggestions={suggestions}
				updateSugg={updateKeys}
			/>
			<div>{selectedKeys?.map(tagWrapper)}</div>
		</div>
	);
}

export default FilterDropdown;
