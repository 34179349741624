import { Provider, useStore } from "react-redux";
import DataWidget from "./DataWidget";
import DisplayWidget from "./DisplayWidget";
import { useParams } from "react-router";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "../../store/login";
import { searchReducer, WidgetState } from "../../store/widget";
import thunk from "redux-thunk";
import { LoginState, MinimalLoginState } from "../../store/login/interface";

export interface CurrentStore {
	login: LoginState;
}

export interface WidgetStore {
	login: LoginState;
	widget: WidgetState;
}

export function confStore({ login }: CurrentStore) {
	return configureStore({
		reducer: combineReducers({
			login: loginReducer,
			widget: searchReducer,
		}),
		middleware: (m) => m().concat(thunk),
		preloadedState: { login, widget: {} },
	});
}

interface WidgetProps {
	website: string;
}

function Widget(props: WidgetProps) {
	const { website } = props;
	const { id } = useParams<{ id?: string }>();
	const store = useStore();
	const {
		login: { userId, userData },
	}: MinimalLoginState = useStore()?.getState();
	store.replaceReducer(
		combineReducers({
			login: loginReducer,
			widget: searchReducer,
		})
	);

	if (!!id && !!userId) {
		const color =
			userData?.newstanks.find((e) => e.name === website)?.color ?? "#722ed1";
		return <DataWidget id={id} userId={userId} color={color} />;
	}
	return <>Nothing to show</>;
}

export default Widget;
export { DataWidget, DisplayWidget };
