import { noop, uniqueId } from "lodash";
import { useStore } from "react-redux";

import { View } from "../../model/vue";
import { Maybe, RSetter } from "../../interfaces/Utils";
import VisNavigator, { KEYS } from "../Projects/VisNavigator";
import MiniPreview from "./Home/MiniPreview";
import InfographicsList from "./InfographicsList";
import { Button, message, Popconfirm } from "antd";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { waitDeletion } from "../../lib/functions";
import { DeleteOutlined } from "@ant-design/icons";

async function removeAndWait(userId: string, uid: string, onEnd: () => void, onErr: (err: string) => void) {
    const url = new URL(`${ROOT_API_URL}/api/v1/doc/view/${uid}`);
    const headers = withAuthorization(userId, newHeaders());
    const method = "DELETE"
    const init = { headers: headers, method: method };
    const r = await fetch(url.href, init);
    if (!r.ok) {
        onErr(`Une erreur s'est produite durant la suppression (code #${r.status}), veuillez réessayer ultérieurement ou prévenir l'équipe Techno`);
        return;
    }
    waitDeletion("view", userId, uid, onEnd);
}

function miniPreviewWrapper(setLoading: RSetter<boolean>, refresh: () => void) {
    return function (view: View) {
        const {
            info: {
                private: { title, description },
            },
            uid,
            news_tank,
            idx,
            type,
            static: staticV,
            // dynamic,
        } = view;
        const userId: string = useStore().getState()?.login?.userId;
        const actions = ([
            <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer cette visualisation ?"
                okText="Supprimer"
                cancelText="Annuler"
                onConfirm={() => {
                    setLoading(true);
                    removeAndWait(userId, uid, () => {
                        refresh();
                        setLoading(false);
                    }, (err: string) => {
                        message.error(err);
                        setLoading(false);
                    });
                }}
            >
                <Button>
                    <DeleteOutlined />
                    Supprimer
                </Button>
            </Popconfirm>
        ]);

        return (
            <MiniPreview
                key={uniqueId("view-")}
                id={uid}
                title={title}
                description={description}
                indicator={idx}
                nt={news_tank}
                type={type}
                // isDefault={isDefault /** TODO: Fix it properly */}
                isStatic={!!staticV}
                dates={view?.meta?.dates}
                author={view?.meta?.author}
                actions={actions}
            />
        );
    };
}

function InfographicsRemove() {
    return (
        <>
            <VisNavigator current={KEYS.VIEWS} />
            <InfographicsList>
                {(data, setLoading, refresh) => data.map(miniPreviewWrapper(setLoading, refresh))}
            </InfographicsList>
        </>

    );
}

export default InfographicsRemove;