import { useHistory } from "react-router";
import { connect, ConnectedProps, useStore } from "react-redux";


import {
	InfDescription, INFO_SET_LOADING, MinimalInfoState,
} from "../../store/infographics";
import { MinimalLoginState } from "../../store/login/interface";
import { WidgetState } from "../../store/widget";
import { StepDataSelect, StepVisSelect, StepInfCustom } from "./StepCreate";
import { getStringFromParams } from "../../lib/get_params";
import Stepper, { DescStatePair, StepProps } from "./InfoStepper";
import trySaveView from "./StateInterceptorHandlers";

const steps = (_: DescStatePair<InfDescription>): StepProps[] => [
	{
		title: "Quel type de visualisation ?",
		content: <StepVisSelect />,
	},
	{
		title: "Quelles données ?",
		content: <StepDataSelect />,
	},
	{
		title: "Personnalisation",
		content: <StepInfCustom />,
	},
];

// #region store connexion
interface StateProps {
	loading?: boolean;
}
function mapStateToProps(state: MinimalInfoState): StateProps {
	const {
		info: { loading },
	} = state;
	return { loading: loading };
}

interface DispatchProps {
	setLoading: (v: boolean) => void;
}
function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setLoading: function setLoading(v: boolean): void {
			dispatch({ type: INFO_SET_LOADING, payload: { loading: v } });
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

type StateInterceptorProps = ConnectedProps<typeof connector>;
function StateInterceptor(props: StateInterceptorProps) {
	const { setLoading } = props;
	const store = useStore<(MinimalInfoState & { widget: WidgetState } & MinimalLoginState)>();
	const h = useHistory();

	const projectId = getStringFromParams("projectId");

	return (
		<Stepper
			steps={steps}
			successMessage="Votre infographie a bien été créée, retrouvez la dans votre projet"
			errorMessage="Loupé !"
			onFinish={() => trySaveView(projectId, store, setLoading, h)}
		/>
	);
}

export default connector(StateInterceptor);
