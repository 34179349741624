import { connect, ConnectedProps } from "react-redux";
import moment from "moment";

import locale from "antd/lib/date-picker/locale/fr_FR";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DatePicker, Input, Row, Space, Switch, Tooltip, Typography } from "antd";

import { DataKey, MetaAccess, MetaKey } from "../../../model/vue";
import {
	InfDescription,
	INFO_SET_DESCRIPTION,
	setInfo as setInfoAction,
	setMeta as setMetaAction,
	setDynamic as setDynamicAction,
} from "../../../store/infographics";
import { OnChangePayload } from "../../Projects/Edit/interfaces";
import { CurrentStore } from "./StepInfCustom";
import { frenchFormat } from "../../../interfaces/editorial";

const { Title } = Typography;

// #region Infographic connection to redux
interface StateToProps {
	desc: InfDescription;
}

interface DispatchToProps {
	setDesc(desc: InfDescription): void;
	setInfo(outer: MetaAccess, inner: MetaKey, value: string): void;
	setMeta(key: DataKey, value: string): void;
	setDynamic(staticV?: moment.Moment): void;
}

const mapStateToProps = ({ info: { desc } }: CurrentStore): StateToProps => ({
	desc: desc,
});
const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
	setDesc: function setDesc(desc: InfDescription): void {
		dispatch({ type: INFO_SET_DESCRIPTION, payload: { desc: desc } });
	},
	setInfo: function setInfo(outer: MetaAccess, inner: MetaKey, value: string): void {
		dispatch(setInfoAction(outer, inner, value));
	},
	setMeta: function setMeta(key: DataKey, value: string): void {
		dispatch(setMetaAction(key, value));
	},
	setDynamic: function setDynamic(staticV?: moment.Moment): void {
		dispatch(setDynamicAction(staticV));
	},
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

type InfographicDescriptionProps = ConnectedProps<typeof connector>;

function createOnChangeInfo(
	setInfo: (access: MetaAccess, key: MetaKey, value: string) => void
) {
	return function (outer: MetaAccess, inner: MetaKey) {
		return function (pl: OnChangePayload) {
			const {
				target: { value },
			} = pl;
			setInfo(outer, inner, value);
		};
	};
}

function createOnChangeMeta(setMeta: (key: DataKey, value: string) => void) {
	return function (key: DataKey) {
		return function (pl: OnChangePayload) {
			const {
				target: { value },
			} = pl;
			setMeta(key, value);
		};
	};
}

function InfographicDescription(props: InfographicDescriptionProps) {
	const {
		desc: { info, meta },
		desc,
		setInfo,
		setMeta,
		setDynamic,
	} = props;
	const onChangeInfo = createOnChangeInfo(setInfo);
	const onChangeMeta = createOnChangeMeta(setMeta);
	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Title level={4}>Autour de la vue</Title>
			<Row justify="space-between">
				<Space direction="vertical" style={{ width: "49%" }}>
					<Title level={5}>Privé</Title>
					<div>
						<span style={{ color: "#f5222d" }}>*</span>
						Titre :
					</div>
					<Input
						value={info?.private.title ?? desc.title ?? ""}
						onChange={onChangeInfo("private", "title")}
					/>
					<div>Description :</div>
					<Input.TextArea
						value={info?.private.description ?? desc.comment ?? ""}
						onChange={onChangeInfo("private", "description")}
					/>
					<Space style={{ marginTop: 6 }}>
						Faut-il afficher les futures données saisies dans cette vue ?
						<Tooltip
							placement="top"
							title="Les données d'une vue dynamique sont mises à jour dans le temps à chaque nouvelle entrée.
							Convient pour les indicateurs dans l'espace data. Peut ne pas convenir pour les insertions en article."
						>
							<InfoCircleOutlined
								style={{ marginLeft: 6, color: "rgba(0, 0, 0, 0.45" }}
							/>
						</Tooltip>
						<Switch
							size="small"
							checked={desc.static === undefined}
							checkedChildren="Oui"
							unCheckedChildren="Non"
							onChange={(ch) => {
								setDynamic(ch ? undefined : moment.parseZone(moment.now()));
							}}
						/>
					</Space>
					<Row align="middle" justify="space-between">
						Jusqu'à quelle date les données ajoutées doivent-elles être incluses dans la vue ? 
						<DatePicker
							placeholder="Choisir la date de fin"
							locale={locale}
							format={"DD/MM/YYYY - HH:mm"}
							onChange={v => setDynamic(v === null ? moment.parseZone(moment.now()) : v)}
							value={desc.static === undefined
								? moment.parseZone(moment.now())
								: (moment.isMoment(desc.static)
									? desc.static
									: moment.parseZone(desc.static, frenchFormat)
								)
							}
							disabled={desc.static === undefined}
						/>
					</Row>
				</Space>
				<Space direction="vertical" style={{ width: "49%" }}>
					<Title level={5}>Public</Title>
					<div>Titre :</div>
					<Input
						value={info?.public?.title}
						onChange={onChangeInfo("public", "title")}
					/>
					<div>Commentaire :</div>
					<Input.TextArea
						value={info?.public?.description}
						onChange={onChangeInfo("public", "description")}
					/>
					<div>Sources :</div>
					<Input.TextArea value={meta?.sources} onChange={onChangeMeta("sources")} />
				</Space>
			</Row>
		</Space>
	);
}

export default connector(InfographicDescription);
