import { Button, Card, DatePicker, Input, InputNumber, Tooltip, Row, Select, Typography, message } from "antd";
import { Map, Maybe } from "../../../../interfaces/Utils";
import { Prop } from "../../../Indicators/Management/interface";
import Result from "../../../Result";
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useStore } from "react-redux";
import { CLOSE_SIDEBAR } from "../../../../store/sidebar";
import { loginFailedAction } from "../../../../store/login";
import { MinimalLoginState } from "../../../../store/login/interface";
import { useEffect, useState } from "react";
import { dataDescription, DataDescription } from "./dataDescription";
import { saveProps } from "./network";

const { Title } = Typography;
const { Option } = Select;

function getComponent(mapData: Map<any>, props: DataDescription, onKeyChange: (key: string, value: any) => void): React.ReactNode {
	const { inputType, key, selectData, frozen, render, } = props;
	const datum = mapData[key];
	if (render) {
		return (render(datum, mapData));
	}

	if (frozen) {
		return (<div>{datum}</div>)
	}

	const onChange = (v: any) => onKeyChange(key, v?.target?.value ?? v);
	switch (inputType ?? "string") {
		case "select":
			return (
				<Select value={datum} onChange={onChange}>
					{(selectData || []).map((e) => (
						<Option value={e}>{e}</Option>
					))}
				</Select>
			);
		case "number":
			return <InputNumber value={datum} onChange={onChange} decimalSeparator="," />;
		case "date":
			return <DatePicker value={datum} onChange={onChange} />;
		case "string":
		default:
			return <Input value={datum} onChange={onChange} />;
	}
}

const PropEdit = ({ data }: { data?: Prop }) => {
	const dispatch = useDispatch();
	const { login: { userId } } = useStore<MinimalLoginState>().getState();

	const [datum, setDatum] = useState<Maybe<Prop>>(data);

	useEffect(() => setDatum(data), [data]);

	if (!data || !datum || !userId) {
		return <Result status={500} />
	}

	const { name, uid, } = datum;
	const closeSider = () => dispatch({ type: CLOSE_SIDEBAR });

	const onKeyChange = (key: string, value: any): void => {
		const newData: Prop = {
			...datum,
			[key]: value,
		}
		setDatum(newData);
	}

	const dispatch401 = (code: number, msg: string) => {
		switch (code) {
			case 401: {
				dispatch(loginFailedAction(401, msg));
				break;
			}
			default: {
				message.error(`code: ${code} - message: ${msg}`);
				break;
			}
		}
	}

	const saveProp = saveProps(userId, uid, datum as unknown as Record<string, string>, dispatch401);

	return (
		<Card
			bordered={false}
			title={
				<span style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					height: '60px'
				}}>
					Édition - {name}
					<Button type="link" onClick={closeSider}>
						<CloseOutlined />
					</Button>
				</span>
			}
			style={{
				height: '100vh',
				padding: 12,
				overflowY: 'scroll'
			}}
			actions={[
				<div
					style={{
						textAlign: 'right',
						height: '32px',
					}}
				>
					<Button style={{ marginRight: 8, bottom: '0' }} onClick={closeSider}>
						Annuler
					</Button>
					<Button type="primary" onClick={saveProp}>
						Sauvegarder
					</Button>
				</div>
			]}
		>
			<div style={{ height: '60vh' }}>
				{dataDescription(userId).map(function (props: DataDescription) {
					const { key, title, description, icon, } = props;
					const component = getComponent(datum, props, onKeyChange);
					const titleElement = description
						? (
							<Title level={5}>
								{title ?? key}
								<Tooltip
									placement="top"
									title={description}
								>
									<InfoCircleOutlined style={{ marginLeft: 6, color: "rgba(0, 0, 0, 0.45" }} />
								</Tooltip>
							</Title>
						)
						: (icon
							? <Row>
								<div style={{ marginRight: 10 }}>{title ?? key}</div>
								{icon(data)}
							</Row>
							: <div>{title ?? key}</div>);
					return (
						<div style={{ marginBottom: 20, }}>
							<Title level={5}>{titleElement}</Title>
							<Row gutter={8}>{component}</Row>
						</div>
					)
				})}
			</div>
		</Card>
	);
};

export default PropEdit;
