import React, { useState } from "react";
import { Row, Radio, DatePicker as AntDatePicker, Tag } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment from "moment";

import "moment/locale/fr";

import locale from "antd/lib/date-picker/locale/fr_FR";
import Search, { defaultPagination } from "../../../../model/search";
import { paginationLens, paramsLens } from "../../lenses";
import { remove } from "../../../../lib/array";
import { Maybe } from "../../../../interfaces/Utils";

const { Group } = Radio;
const { RangePicker } = AntDatePicker;

const EXACT_DATE = "EXACT_DATE";
const RANGE_DATE = "RANGE_DATE";
const DEFAULT_MODE = RANGE_DATE;
const modesOptions = [
	{
		value: EXACT_DATE,
		label: (
			<span
				style={{
					width: 104,
					display: "flex",
					justifyContent: "center",
				}}
			>
				Date
			</span>
		),
	},
	{
		value: RANGE_DATE,
		label: (
			<span
				style={{
					width: 104,
					display: "flex",
					justifyContent: "center",
				}}
			>
				Période
			</span>
		),
	},
];

const frFmt = "DD/MM/YYYY";

// TODO: Fill it properly
// const popover = {
// 	title: 'Comment ça marche ?',
// 	content: (<div>
// 		{Array
// 			.from({ length: 5 }, (_, i) => i)
// 			.map(i => <div key={_uniqueId("expl-")}>Explication {i}</div>)
// 		}
// 	</div>),
// };

export const modes = Object.freeze({ EXACT_DATE: EXACT_DATE, RANGE_DATE: RANGE_DATE });

interface DatePickerProps extends FilterDropdownProps {
	e: any;
	search: Search;
	updateSearch(s: Search): void;
}

// TODO: Move in a lib maybe ? Are a specialised display lib for the project
export function formattedMoment(v: any): Maybe<string> {
	if (moment.isMoment(v)) {
		return (v as moment.Moment).format(frFmt);
	} else if (Array.isArray(v)) {
		const [beg, end] = v;
		const begS = moment.isMoment(beg) ? beg.format(frFmt) : undefined;
		const endS = moment.isMoment(end) ? end.format(frFmt) : undefined;
		if (!!begS && !!endS) return `Du ${begS} au ${endS}`;
		else if (!!begS) return `À partir du ${begS}`;
		else if (!!endS) return `Jusqu'au ${endS}`;
	}
	return undefined;
}

export default function DatePicker(props: DatePickerProps) {
	const { search, updateSearch, e } = props;

	const index = e?._dataIndex;
	const prevParams = paramsLens.get(search);
	const prevVal = prevParams[index];

	const [mode, setMode] = useState(DEFAULT_MODE);

	const options = {
		locale: locale,
		format: frFmt,
		value: null,
		style: { width: 256 },
		onChange(newVal: any) {
			const s = paramsLens.set(search, {
				...prevParams,
				[index]: [...(prevVal || []), newVal],
			});
			const s2 = paginationLens.set(s, defaultPagination);
			updateSearch(s2);
		},
		onKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
			const { key } = evt;
			if (key !== "Enter") return;

			const parent = document.getElementsByClassName("inner-hacky-ref");
			const children = parent[0]?.children;
			const values: (moment.Moment | undefined)[] = [];
			for (let i = 0; i < children.length; i++) {
				let item = children.item(i);
				if (item?.className?.indexOf("ant-picker-input") === -1) continue;
				const child = item?.children.item(0);
				const value = child?.getAttribute("value") ?? "";
				values.push(!!value ? moment(value) : undefined);
			}

			const s = paramsLens.set(search, {
				...prevParams,
				[index]: [...(prevVal || []), values],
			});
			const s2 = paginationLens.set(s, defaultPagination);
			updateSearch(s2);
		},
		className: "inner-hacky-ref",
	};

	let picker;

	switch (mode) {
		case EXACT_DATE: {
			picker = <AntDatePicker size="small" {...options} />;
			break;
		}
		case RANGE_DATE:
		default: {
			picker = <RangePicker size="small" {...options} />;
		}
	}

	const tags = prevVal?.map((v, i) => {
		const tagProps = {
			style: { cursor: "pointer", margin: 5 },
			onClick: (_: any): void => {
				updateSearch(
					paramsLens.set(search, {
						...prevParams,
						[index]: remove(prevVal, i),
					})
				);
			},
		};
		const s = formattedMoment(v);
		return !!s ? <Tag {...tagProps}>{s}</Tag> : null;
	});

	const tagsWrapperStyle = {
		display: "flex" as const,
		flexDirection: "column" as const,
		alignContent: "center" as const,
		justifyContent: "center" as const,
	};

	return (
		<div style={{ width: 256, padding: 8 }}>
			<Row style={{ display: "flex", justifyContent: "space-between" }}>
				<Group
					options={modesOptions}
					onChange={({ target: { value } }) => setMode(value)}
					value={mode}
					optionType="button"
					buttonStyle="solid"
					size="small"
				/>
				{/* <Popover {...popover}>
					<QuestionCircleOutlined style={{ cursor: 'help', marginRight: 10 }} />
				</Popover> */}
			</Row>
			<Row style={{ marginTop: 8 }}>{picker}</Row>
			<Row style={{ marginTop: !!tags ? 8 : 0 }}>
				{!!tags ? <div style={tagsWrapperStyle}>{tags}</div> : null}
			</Row>
		</div>
	);
}
