import React, { MutableRefObject, useRef } from "react";

import { Button, FormInstance, message, Row, Space } from "antd";

import _ from "lodash";

import { KeyDescription } from "./interfaces";
import { Map, Maybe, RSetter } from "../../../../interfaces/Utils";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../lib/fetch";
import { TimedResponse } from "../../../../store/widget/interfaces";
import { EDITION_STATUSES, Item } from "../interface";
import Search from "../../../../model/search";
import AddData from "./AddData";

interface ActionPayload {
	created: Map<any>[];
	edited: Map<any>[];
	deleted: Map<any>[];
}

interface ActionKeys {
	createdK: React.Key[];
	editedK: React.Key[];
	deletedK: React.Key[];
}

interface ErrOrId { error?: string, id?: string }

interface ActionResponse {
	created: ErrOrId[],
	edited: ErrOrId[],
	deleted: ErrOrId[],
}

interface ExporterError {
	code: number;
	message: string;
}

interface ExporterReturn {
	error?: ExporterError;
	result?: Maybe<ActionResponse>;
}

async function direxport(
	userId: Maybe<string>,
	id: string,
	keys: Maybe<KeyDescription[]>,
	newData: ActionPayload,
): Promise<ExporterReturn> {
	if (!userId || !keys) {
		return {
			error: {
				code: -1,
				message: !userId ? "il manque la userId" : "il manque les clefs spécialisées",
			}
		};
	}
	const url = new URL(`${ROOT_API_URL}/neo/node/new`);
	const headers = withAuthorization(userId, newHeaders());
	const method = "POST";

	const { created, deleted, edited, } = newData;
	if (!(created.length > 0 || deleted.length > 0 || edited.length > 0)) {
		message.warning("Aucune modification à sauvegarder.");
		return {
			result: undefined,
		};
	}
	const body = JSON.stringify({ indicator: id, data: newData });
	const init: RequestInit = { headers: headers, body: body, method: method }
	const r = await fetch(url.href, init);
	if (r.ok) {
		const b: TimedResponse<ActionResponse, never> = await r.json();
		// !TODO: do the diff and inform user and clean the list
		return {
			result: b.data,
		};
	}
	return {
		error: {
			code: r.status,
			message: await r.text(),
		}
	};
}

export function addRow(
	form: FormInstance<any>,
	keys: KeyDescription[],
	editedData: Item[],
	setState: RSetter<EditState>,
	initialData?: { values?: string[], keys: string[] },
) {
	return () => {
		const newData: Map<any> = keys.reduce(
			(acc, { key }) => ({ ...acc, [key]: undefined }),
			{}
		);
		const key: React.Key = _.uniqueId("data-");
		let newItem: Item = { ...newData, key: key, __status: EDITION_STATUSES.NEW };
		if (initialData && initialData.values) {
			const { values, keys } = initialData;
			let e = keys.reduce((acc, cur, i) => {
				const val = values[i];
				if (cur === undefined) {
					return acc;
				}
				const ks = cur.split(".");
				if (ks.length > 1) {
					const outer = acc[ks[0]];
					if (outer !== undefined) {
						outer[ks[1]] = val;
					} else {
						acc[ks[0]] = { [ks[1]]: val };
					}
				} else {
					acc[cur] = val;
				}
				return acc;
			}, {} as Map<any>);
			newItem = {
				...newItem,
				...e,
				key: key,
				__status: EDITION_STATUSES.NEW,
			}
		}
		const newItems = [newItem, ...editedData];
		setState({ editingKey: "", editedData: newItems, saving: true });
		form.resetFields();
		form.setFieldsValue(newItem);
		setState({ editedData: newItems, editingKey: key, saving: false });
	}
}

export interface EditState {
	editedData: Item[];
	editingKey: React.Key;
	oldValue?: Item;
	saving: boolean;
	errors?: string[];
}

function printError(action: string) {
	return (eoi: Splitted<ErrOrId>, i: number) => {
		const { elem: { error, id } } = eoi;
		let msg: string;
		if (error === undefined || error === "") {
			if (id !== undefined && id === "") {
				msg = `id manquante`;
			} else {
				msg = `erreur introuvable, merci de faire un ticket`;
			}
		} else {
			msg = error;
		}
		message.error(`${action}: Ligne #${i}: ${msg}`, 0);
	}
}

interface Splitted<T> {
	elem: T,
	id: number,
}

function split<T>(slice: T[], f: (t: T) => boolean): [Splitted<T>[], Splitted<T>[]] {
	const left: Splitted<T>[] = [], right: Splitted<T>[] = [];
	for (let i = 0; i < slice.length; i++) {
		const e = slice[i];
		(f(e) ? left : right).push({ elem: e, id: i });
	}
	return [left, right];
}

function assertErrOrId(e: ErrOrId): boolean {
	const { id } = e;
	return (id !== undefined && id !== "");
}

function removeIndices<T>(slice: T[], ...toRemove: number[]): T[] {
	const n: T[] = [];
	for (let i = 0; i < slice.length; i++) {
		if (toRemove.indexOf(i) !== -1) {
			continue;
		}
		n.push(slice[i]);
	}
	return n;
}

function hasModifications(data: Item[]): boolean {
	return data.findIndex(e => {
		const { _created, _edited, _deleted } = e;
		return (_deleted !== undefined)
			|| (_created === undefined)
			|| (_edited !== undefined)
	}) !== -1;
}

interface HeaderButtonsProps {
	userId: Maybe<string>;
	id: string;
	form: FormInstance<any>;
	keys: KeyDescription[];
	state: EditState;
	total: number;
	size: number;
	current: number;
	setState: RSetter<EditState>;
	cols: { title: string, dataIndex: string }[];
	descs: Map<{ data_type: string, title: string }>;
	arrays: Map<string>;
	requiredValues: string[];
	title: string;
}

function HeaderButtons(props: HeaderButtonsProps) {
	const {
		id, userId, keys, state, form, total, size, current, setState,
		cols, descs, arrays, requiredValues, title,
	} = props;
	const { editedData, editingKey } = state;
	const editState: MutableRefObject<[EditState, RSetter<EditState>]> = useRef([state, setState]);
	async function saveData() {
		// !TODO: clean exported data
		const newData: ActionPayload & ActionKeys = editedData.reduce<ActionPayload & ActionKeys>((acc, cur) => {
			const { _created, _edited, _deleted, key, ...restProps } = cur;
			const { created, deleted, edited, createdK, editedK, deletedK } = acc;
			if (_deleted !== undefined) {
				return {
					...acc,
					deleted: [...deleted, restProps],
					deletedK: [...deletedK, key],
				};
			}
			if (!_created) {
				return {
					...acc,
					created: [...created, restProps],
					createdK: [...createdK, key],
				};
			}
			if (_edited !== undefined) {
				const newItem = { ...restProps, _created: _created, _edited: _edited };
				return {
					...acc,
					edited: [...edited, newItem],
					editedK: [...editedK, key],
				};
			}
			return acc;
		}, {
			created: [],
			edited: [],
			deleted: [],
			createdK: [],
			editedK: [],
			deletedK: [],
		});

		setState({ ...state, saving: true, });
		const { createdK, editedK, deletedK, ...exportPayload } = newData;
		const { error, result } = await direxport(userId, id, keys, exportPayload);
		if (error) {
			const { code, message: msg } = error;
			message.error(`${code}: ${msg}`);
			setState({ ...state, saving: false });
			return;
		} else if (!result) {
			message.success("Aucune action effectué.");
			return;
		}

		const { created, edited, deleted } = result;
		const [okC, errC] = split(created, assertErrOrId);
		const [okE, errE] = split(edited, assertErrOrId);
		const [okD, errD] = split(deleted, assertErrOrId);
		const toRemove: number[] = [];
		const toUnedit: number[] = [];
		const toFetch: string[] = [];
		function addToFetch(id: string) { toFetch.push(id); }
		function pushToAction(keys: React.Key[], arr: number[], saveId?: (id: string) => void) {
			return (v: Splitted<ErrOrId>) => {
				const { id } = v;
				const key = keys[id];
				const itemId = editedData.findIndex((e) => e.key === key);
				if (itemId === undefined || itemId === -1) {
					message.error(`une erreur est survenue`);
					return;
				}
				arr.push(itemId);
			}
		}
		okC.forEach(pushToAction(createdK, toUnedit, addToFetch));
		okE.forEach(pushToAction(editedK, toUnedit, addToFetch));
		okD.forEach(pushToAction(deletedK, toRemove));
		errC.forEach(printError("Création"));
		errE.forEach(printError("Édition"));
		errD.forEach(printError("Suppression"));

		let blockReload = false;
		if (errC.length > 0 || errE.length > 0 || errD.length > 0) {
			message.error("Comme des erreurs on eut lieu, nous avons retiré les actions réussies, les changements sont en train de s'appliquer.\nMerci de gérer les erreurs restantes.")
			blockReload = true;
		}

		// removing all successful operations
		const newEditedData = removeIndices(editedData, ...toRemove, ...toUnedit);
		setState({ ...state, saving: true, editedData: newEditedData });
		// fetch all new documents as they are updated
		// const updated = Promise.all(toFetch.map )
		if (!blockReload) {
			message.success("Operation réussie : la page va se recharger");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} else {
			setState({ ...state, saving: false });
		}
	}

	const realAddRow = addRow(form, keys, editedData, setState);
	const editing = !!editingKey;

	return (
		<Row justify="space-between">
			<Space style={{ marginBottom: 12 }}>
				<AddData
					headers={cols}
					keys={descs}
					editState={editState}
					arrays={arrays}
					requiredValues={requiredValues}
					title={title}
				/>
				<div>
					{`Eléments ${(current - 1) * size + 1} à ${(current) * size} sur ${total}`}
				</div>
				{/* <div>
					Nombre d'élements par chargement(s):
					<Select
						defaultValue={size}
						options={[20, 50, 100, 200].map(v => ({ label: v, value: v }))}
						onSelect={function (sz) {
							updateSearch({...search, pagination: {
								...(search.pagination ?? defaultPagination),
								size: sz,
							}})
						}}
					></Select>
				</div> */}
				<Button
					disabled={editing || !hasModifications(editedData)}
					onClick={!!editingKey ? undefined : saveData}
				>
					Sauvegarder
				</Button>
				<Button
					type="primary"
					disabled={editing}
					onClick={!!editingKey ? undefined : realAddRow}
				>
					Ajouter une ligne
				</Button>
			</Space>
		</Row>
	);
}

export default HeaderButtons;
