import _ from "lodash";

import { message, Select } from "antd";
import { useState } from "react";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { Aggregation } from "../../model/vue";

async function getSuggs(key: string, value: string, userId: string) {
    const url = new URL(`${ROOT_API_URL}/api/v1/suggs`);
    url.searchParams.append("key", key);
    url.searchParams.append("value", value);
    const headers = withAuthorization(userId, newHeaders());
    const init = { headers: headers };
    try {
        const r = await fetch(url.href, init);
        if (!r.ok) {
            return [];
        }
        const b: Aggregation[] = await r.json();
        return b;
    } catch (e) {
        message.error(e as any);
        return [];
    }
}

function aggregAsOption(agg: Aggregation) {
    const label = (agg.doc_count > 0)
        ? `${agg.key} (${agg.doc_count})`
        : agg.key;
    return ({ key: agg.key, value: agg.key, label: label });
}

interface TagSelectWithSuggestionProps {
    route: string;
    userId?: string;
    searchKey: string;
    onChange?: (v: string[]) => void;
    value?: string[];
}

function TagSelectWithSuggestion(props: TagSelectWithSuggestionProps) {
    const [options, setOptions] = useState<Aggregation[]>([]);
    const { onChange, userId, searchKey, value } = props;
    return <Select
        mode="multiple"
        value={value}
        onSearch={async function (value: string) {
            if (!userId) {
                message.error("Il manque l'ID utilisateur, veuillez recharger la page.");
                return;
            }
            let v = await getSuggs(searchKey, value, userId);
            if (v.find(e => e.key === value) === undefined) {
                v = [{ key: value, doc_count: 0 }, ...v];
            }
            setOptions(v);
        }}
        onChange={function (values: string[]) {
            onChange?.(values);
        }}
        onSelect={function (fvalue: string) {
            onChange?.([...(value ?? []), fvalue]);
        }}
        options={options.map(aggregAsOption)}
    />
}

export default TagSelectWithSuggestion;
