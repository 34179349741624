import Vue from "../../../../model/vue";

function TableHeader({ vue }: { vue: Vue }) {
	const description = vue.rawView?.info.public?.description;
	if (!description) {
		return null;
	}

	return <div>{description}</div>;
}

function TableTitle({ title }: { title?: string }) {
	if (!title) {
		return null;
	}

	return <h1>{title}</h1>;
}

interface ViewInfoProps {
	vue: Vue;
	hideInfo?: boolean;
}

function ViewInfo(props: ViewInfoProps) {
	const { hideInfo, vue } = props;

	if (hideInfo || window.location.pathname.startsWith("/data/dashboard")) {
		return null;
	}

	return (
		<>
			<TableTitle title={vue.rawView?.info.public?.title} />
			<TableHeader vue={vue} />
		</>
	);
}

export default ViewInfo;
