import React, { useLayoutEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Layout as BaseLayout, } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, } from '@ant-design/icons';

import { Lens } from '../../lib/lens';

import defaultState, { DLState } from './state';
import AppMenu from './AppMenu';
import AppLocationsMap from './AppLocationsMaps';
import Sidebar from './Sidebar';


import '../../css/layout/index.css';

const { Header, Sider, Content } = BaseLayout;

const collapsedLens: Lens<DLState, boolean> = {
	get({ collapsed }) { return collapsed },
	set(s, collapsed) { return { ...s, collapsed, } },
};

// If you want to have custom props, uncomment this line and add you own
// And comment the one after
// interface LayoutProps extends ConnectedProps<typeof connector>{ }
type LayoutProps = {};

const Layout: React.FC<LayoutProps> = (props) => {
	const { children } = props;
	const location = useLocation();
	const header = AppLocationsMap[location.pathname ?? "..."];
	const [state, setState] = useState(defaultState());
	const { collapsed, breakpoint, collapsedWidth, siderWidth, } = state;
	function updateCollapsed(v: boolean = !collapsed) {
		setState(collapsedLens.set(state, v));
	}
	const toggle = () => updateCollapsed();
	const updateWindowDimensions = () => setState(defaultState());

	useLayoutEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);

	return (
		<BaseLayout className='AppLayout'>
			<Sider
				className="AppLayout-left-sider"
				breakpoint={breakpoint}
				collapsedWidth={collapsedWidth}
				theme="light"
				onBreakpoint={updateCollapsed}
				width={siderWidth}
				collapsible={true}
				collapsed={collapsed}
				trigger={null}
			>
				<AppMenu collapsed={collapsed} />
			</Sider>
			<BaseLayout className="AppLayout">
				<Header id="app-header" className="AppLayout-header">
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: toggle,
					})}
					<span className="AppLayout-header-title">{header}</span>
				</Header>
				{/* <AppBreadcrumbs /> */} {/* TODO: REDO Breadcrumbs */}
				<Content className="Content">
					{children}
				</Content>
			</BaseLayout>
			<Sidebar />
		</BaseLayout>
	);
};

export default Layout;
