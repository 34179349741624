import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import _ from "lodash";

import { useForm } from "antd/lib/form/Form";
import { CloseOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	DatePicker,
	Input,
	InputNumber,
	Row,
	Checkbox,
	Form,
	Card,
	Space,
	Select,
} from "antd";

import { Map, Maybe, RDSA } from "../../../../interfaces/Utils";
import { AllowOrigin, ROOT_API_URL } from "../../../../lib/fetch";
import { capitalize } from "../../../../lib/strings";
import { MinimalLoginState } from "../../../../store/login/interface";
import { CloseAction } from "../../../../store/sidebar";

import { Node } from "../../interface";

import MaybeSpinner from "../../../tools/MaybeSpinner";
import inputMap from "./inputMap.json";
import defaultMasks from "../common/masks";
import { cardStyle } from "../common/constants";
import metaOptions from "./meta_sectors.json";

const im: Map<Map<string>> = inputMap;

interface LoadAndError {
	loading?: boolean;
	err?: string;
}

function editor(node: Node, setNode: RDSA<Node>) {
	return (k: string) => (value: any) =>
		setNode({
			labels: node.labels,
			attributes: {
				...node.attributes,
				[k]: value,
			},
		});
}

function SaveNode(
	userId: Maybe<string>,
	id: string,
	node: Node,
	onOk: (r: Response) => void,
	onErr: (r: Response) => void,
	setLoading: (v: boolean) => void
) {
	return () => {
		if (!userId) {
			console.error("No access granted!");
			return;
		}

		const headers = {
			...AllowOrigin,
			Authorization: userId,
		};

		setLoading(true);
		fetch(`${ROOT_API_URL}/neo/node/edit/${id}`, {
			headers: headers,
			method: "POST",
			body: JSON.stringify({
				labels: node.labels,
				attrs: node.attributes,
			}),
		}).then((r) => {
			if (r.ok) onOk(r);
			else onErr(r);
		});
	};
}

//! TODO: Keep but move elsewhere, behaviour to fix
function useAutoclose(callback: () => void) {
	const location = useLocation();
	const locRef = useRef<any>();
	useEffect(() => {
		if (locRef.current === undefined) {
			locRef.current = location;
		} else {
			locRef.current = undefined;
			callback();
		}
	}, [location]);
}

function getElementByType(type: string): JSX.Element | null {
	switch (type) {
		case "bigint":
		case "number":
			return <InputNumber decimalSeparator="," />;
		case "date":
			return <DatePicker />;
		case "boolean":
			return <Checkbox />;
		case "string":
		default:
			return <Input />;
	}
}

interface NodeEditProps {
	data: Node;
}

function NodeEdit(props: NodeEditProps) {
	const { data } = props;
	const {
		login: { userId },
	}: MinimalLoginState = useStore().getState();
	const [node, setNode] = useState<Node>(data);
	const [loadAndMessage, setLoadAndMessage] = useState<LoadAndError>({});
	const { loading, err: error } = loadAndMessage;
	const reduxDispatch = useDispatch();
	const {
		attributes,
		attributes: { uid: id },
	} = node;
	const joinedLabels = node.labels.sort().join(".");
	const types = im[joinedLabels] ?? {}; // Add its fetching
	function closeSidebar() {
		reduxDispatch(CloseAction);
	}
	// const onChange = editor(node, setNode);

	const onDiscard = closeSidebar;
	const [form] = useForm();

	const cardTitle = (
		<span
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "flex-start",
				height: "60px",
			}}
		>
			Édition
			<Button type="link" onClick={onDiscard}>
				<CloseOutlined />
			</Button>
		</span>
	);

	const card = (
		<Card bordered={false} title={cardTitle} style={cardStyle}>
			{error ? <Alert message={error} type="error" /> : null}
			<Form
				form={form}
				name="edit-form"
				initialValues={attributes}
				onFinish={function (values) {
					SaveNode(
						userId,
						id,
						{
							labels: data.labels,
							attributes: values,
						},
						(_) => window.location.reload(),
						(r) =>
							setLoadAndMessage({
								loading: false,
								err: `${r.status} - ${r.statusText}`,
							}),
						(loading) => setLoadAndMessage({ loading: loading })
					)();
				}}
				onError={(err) => {
					console.log(err);
				}}
			>
				{_.reduce(
					attributes,
					(acc, _, key) => {
						if (key === "uid" || key.startsWith("_")) {
							return acc;
						}
						const type = types[key] ?? "string";
						const title = defaultMasks[key] ?? capitalize(key);
						const e = getElementByType(type);
						const elem = (
							<Form.Item name={key} label={title}>
								{e}
							</Form.Item>
						);
						return [...acc, elem];
					},
					[] as JSX.Element[]
				)}
				<Form.Item>
					<Row justify="end">
						<Space>
							<Button type="primary" htmlType="submit">
								Sauvegarder
							</Button>
							<Button onClick={onDiscard}>Annuler</Button>
						</Space>
					</Row>
				</Form.Item>
			</Form>
		</Card>
	);

	return <MaybeSpinner loading={loading}>{card}</MaybeSpinner>;
}

export default NodeEdit;
