import {
	DeleteOutlined,
	EditOutlined,
	QuestionCircleOutlined,
	TableOutlined,
} from "@ant-design/icons";
import { Button, Card, Popconfirm, Row, Typography } from "antd";

import { Maybe, RSetter } from "../../../interfaces/Utils";
import { ProjectBuilderData, ViewAndUsed } from "./interfaces";
import { removeVue } from "./vueHandlers";

const { Title, Text } = Typography;

interface ViewProps {
	viewAndUsed: ViewAndUsed;
	projectId: Maybe<string>;
	userId: string;
	state: ProjectBuilderData;
	setState: RSetter<ProjectBuilderData>;
}

function View(props: ViewProps) {
	const { viewAndUsed, projectId, userId, state, setState } = props;
	const { usedIn, view } = viewAndUsed;
	const {
		type,
		uid: id,
		info: {
			private: { title, description },
		},
	} = view;
	const plural = usedIn > 2 ? "s" : "";
	const onClick = removeVue(projectId ?? "", id, userId, state, setState);
	return (
		<Card
			style={{
				width: 710,
				marginBottom: 8,
			}}
			actions={[
				<>
					{/* Editer la vue */}
					<Button
						href={`/visualisations/vue/edit/${id}?projectId=${projectId}`}
						type="link"
					>
						<EditOutlined />
						Éditer
					</Button>
				</>,
				<>
					{/* Retirer de la liste */}
					<Popconfirm
						title="Retirer la vue du projet ?"
						onConfirm={onClick}
						icon={<QuestionCircleOutlined style={{ color: "red" }} />}
						cancelText="Annuler"
					>
						<Button type="link">
							<DeleteOutlined />
							Retirer
						</Button>
					</Popconfirm>
				</>,
			]}
		>
			<Row align="middle">
				{type === "table" ? (
					<TableOutlined
						style={{
							height: 32,
							width: 32,
							color: "#9254de",
							marginRight: 8,
						}}
					/>
				) : (
					type
				)}
				<Title level={5}>{title || "Aucun titre donné"}</Title>
			</Row>
			<Text type="secondary">{description || "Aucune description donnée"}</Text>
			{/* <Button type="link" size="small" style={{ width: "100%" }}>
				{usedIn > 1
					? `Utilisée dans ${usedIn - 1} autre${plural} projet${plural}`
					: "Utilisée uniquement dans ce projet"}
			</Button> */}
		</Card>
	);
}

export default View;
