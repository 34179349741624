import _uniqueId from "lodash/uniqueId";

import { Select } from "antd";

export const dateKeys = [
	{
		value: "dates.created,asc",
		label: "Création ↑",
	},
	{
		value: "dates.created,desc",
		label: "Création ↓",
	},
	{
		value: "dates.edited.date,asc",
		label: "Modification ↑",
	},
	{
		value: "dates.edited.date,desc",
		label: "Modification ↓",
	},
	{
		value: "dates.published.date,asc",
		label: "Publication ↑",
	},
	{
		value: "dates.published.date,desc",
		label: "Publication ↓",
	},
];

interface DateSelectorProps {
	value: string;
	setter: React.MutableRefObject<(value: string) => void>;
}

function DateSelector(props: DateSelectorProps) {
	const { value, setter } = props;
	return <Select
		value={value}
		style={{ width: 150 }}
		bordered={false}
		onChange={(value) => setter.current(value)}
	>
		{dateKeys.map(({ value, label }) => (
			<Select.Option value={value}>{label}</Select.Option>
		))}
	</Select>
}

export default DateSelector;
