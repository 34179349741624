import { connect, ConnectedProps } from "react-redux";

import { Button, Table, Progress, Typography } from "antd";

import { ColumnsType } from "antd/lib/table";
import { capitalize } from "../../../lib/strings";
import { CloseAction, OpenAction, SidebarState } from "../../../store/sidebar";
import { Meta, Prop } from "./interface";

const { Title } = Typography;

// #region Sidebar Store Connection
interface StateProps {
	collapsed: boolean;
	data?: Prop;
}

const mapStateToProps = ({
	sidebar: { collapsed, data },
}: {
	sidebar: SidebarState;
}): StateProps => ({
	collapsed: collapsed,
	data: data,
});

interface DispatchProps {
	closeSidebar(): void;
	openSidebar(data: Prop): () => void;
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	closeSidebar() { dispatch(CloseAction) },
	openSidebar(data: Prop): () => void { return () => dispatch(OpenAction("prop-edit", data)); },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

function genCols(data: Prop | undefined, collapsed: boolean, closeSidebar: () => void, openSidebar: (data: Prop) => () => void): ColumnsType<Prop> {
	return [
		{
			title: "Nom pour les clients",
			dataIndex: "mask",
			sorter: (a: Prop, b: Prop) => a.mask.localeCompare(b.mask)
			// width: 250,
		},
		{
			title: "Propreté",
			dataIndex: "cleanliness",
			sorter: (a: Prop, b: Prop) => a.cleanliness - b.cleanliness,
			// sortOrder: "ascend",
			width: 230,
			align: "center",
			render: (c: number) => {
				return (
					<Progress
						style={{ minWidth: 70, marginRight: 8 }}
						percent={(Math.trunc(Math.round(c * 100) / 100))}
					/>
				);
			}
		},
		{
			title: "Priorité",
			dataIndex: "priority",
			// sorter: true,
			align: "center",
			width: 100,
			render: (priority: string) => capitalize(priority)
		},
		{
			title: "Type de données",
			dataIndex: "type",
			// sorter: true,
			width: 150,
			align: "center",
			render: (type: string) => capitalize(type)
		},
		{
			title: "Valeur si vide",
			dataIndex: "flag",
			// sorter: true,
			align: "center",
			width: 150,
		},
		{
			title: "Actions",
			width: 200,
			render: (rec: Prop) => {
				const { uid } = rec;
				const { uid: data_uid } = (data || {}) as Prop;
				const thisAndOpen = (data_uid === uid && !collapsed);
				const onClick = thisAndOpen ? closeSidebar : openSidebar(rec);
				const text = thisAndOpen ? "Fermer" : "Éditer";
				return (
					<Button style={{ color: "#22075e" }} onClick={onClick} type="link">
						{text}
					</Button>
				);
			},
		},
	];
}

interface MetaCardProps extends ConnectedProps<typeof connector> {
	meta: Meta;
	color: string;
	uid: string;
	alwaysShow?: boolean;
	hideActions?: boolean;
}

function MetaCard({ meta, collapsed, data, uid, openSidebar, closeSidebar }: MetaCardProps) {
	const { labels, props } = meta;
	const realLabels = labels.split(',').filter(l => !l.match(/^[A-Z]{3}_/));

	function filter({ name }: Prop): boolean { return name !== 'uid'; }
	function sort({ name: lname }: Prop, { name: rname }: Prop): number {
		return lname.localeCompare(rname);
	}

	return (
		<Table
			dataSource={props.filter(filter).sort(sort)}
			size="small"
			style={{
				marginBottom: 12
			}}
			pagination={false}
			title={() => <Title level={5}>{realLabels.map((l) => l + " ")}</Title>}
			columns={genCols(data, collapsed, closeSidebar, openSidebar)}
		/>
	);
}

export default connector(MetaCard);
