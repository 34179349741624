import { Card, Typography } from "antd";
import {
	TableOutlined,
	BarChartOutlined,
	DotChartOutlined,
	PieChartOutlined,
	LineChartOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";

import { Map } from "../../../interfaces/Utils";
import TagViewGenerator from "../../tools/TagViewGenerator";
import { EditorialDates } from "../../../interfaces/editorial";
import { dateLine } from "../../Projects/Display";

const { Meta } = Card;
const { Text } = Typography;

const defaultIcon = <QuestionCircleOutlined />;
const icons: Map<JSX.Element> = {
	table: <TableOutlined className="HomeInfographics-avatar" />,
	"pie-chart": <PieChartOutlined className="HomeInfographics-avatar" />,
	"dot-chart": <DotChartOutlined className="HomeInfographics-avatar" />,
	"bar-chart": <BarChartOutlined className="HomeInfographics-avatar" />,
	"line-chart": <LineChartOutlined className="HomeInfographics-avatar" />,
};
const viewType: Map<string> = {
	table: "Tableau",
	"pie-chart": "Pie Chart",
	"dot-chart": "Dot Chart",
	"bar-chart": "Bar Chart",
	"line-chart": "Line Chart",
};

function DateInfo(props: {
	dates?: EditorialDates,
	author?: string,
	style?: any,
}) {
	const { dates, author, style } = props;
	if (!dates || !author) {
		return null;
	}


	const rstyle = {
		...(style ?? {}),
		fontSize: "80%",
	};

	return <div
		style={{ marginTop: "10px" }}
	>
		<Text
			type="secondary"
			style={rstyle}
		>
			{dateLine(dates, [author], 'column')}
		</Text>
	</div>
}

function getIcon(type: string): JSX.Element {
	return icons[type] ?? defaultIcon;
}

interface MiniPreviewProps {
	id: string;
	title: string;
	description?: string;
	indicator: string;
	nt: string;
	type: string;
	isStatic?: boolean;
	isDefault?: boolean;
	selected?: string;
	setSelected?: (id: string) => void;
	dates?: EditorialDates;
	author?: string;
	actions?: JSX.Element[];
}

function MiniPreview(props: MiniPreviewProps) {
	const {
		title,
		description,
		type,
		isStatic,
		isDefault,
		id,
		selected,
		setSelected,
		dates,
		author,
		actions,
	} = props;
	const isSelected = id === selected;

	return (
		<Card
			className="MiniPreview-card"
			size="small"
			hoverable={setSelected !== undefined}
			onClick={setSelected ? () => setSelected(id) : undefined}
			style={{
				border: isSelected ? "3px solid #d3adf7" : "",
				minWidth: 500,
				width: "calc(50% - 8px)",
			}}
			cover={
				<span style={{ position: "relative", minWidth: 500 }}>
					<span style={{ position: "absolute", right: 12, top: 12 }}>
						{isDefault === true ? (
							<TagViewGenerator type="default" />
						) : undefined}
						<TagViewGenerator
							type={isStatic === true ? "static" : "dynamic"}
						/>
					</span>
					{/* <PreviewCarousel arrow={false} height={150} width={300} /> */}
				</span>
			}
			actions={actions}
		>
			<Meta
				avatar={getIcon(type)}
				title={title || "Titre manquant"}
				description={description || "Description manquante."}
			/>
			<DateInfo dates={dates} author={author} style={{ width: "420px" }} />
		</Card>
	);
}

export default MiniPreview;
