import { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch, useStore } from "react-redux";

import _uniqueId from "lodash/uniqueId";

import { Space, Typography } from "antd";

import { loginFailedAction } from "../../../store/login";
import { MinimalLoginState, Newstank } from "../../../store/login/interface";
import { AllowOrigin, ROOT_API_URL } from "../../../lib/fetch";
import { Map, Maybe } from "../../../interfaces/Utils";

import { Indicator } from "../../Indicators/Management/interface";
import IndicatorDisplay from "../../Indicators/Display";
import { IndicatorSpec } from "../../Indicators/interface";
import {
	InfDescription,
	InfoPayload,
	InfoState,
	INFO_SET_CHECKED_KEYS,
	INFO_SET_DESCRIPTION,
	INFO_SET_EXPAND_KEYS,
	INFO_SET_PROPS_AND_EXPAND_KEYS,
} from "../../../store/infographics";
import { genSelectedKeys, getPreviewData } from "..";

const { Title } = Typography;

//#region Store connection
interface StateProps {
	properties?: IndicatorSpec;
	expandedKeys: React.Key[];
	checkedKeys: React.Key[];
	active?: string;
	desc: InfDescription;
}

interface DispatchProps {
	setExpandedKeys(k: React.Key[]): void;
	setCheckedKeys(k: React.Key[]): void;
	setPropsAndExpanded(p: Maybe<IndicatorSpec>, k: React.Key[]): void;
	setActive(uid: string, desc: InfDescription): void;
}

function mapStateToProps({ info }: { info: InfoState }): StateProps {
	const { props, expanded, checked, desc } = info;
	return {
		properties: props,
		expandedKeys: expanded,
		checkedKeys: checked,
		active: desc.idx,
		desc: desc,
	};
}

function mapDispatchToProps(dispatch: any, state: any): DispatchProps {
	return {
		setExpandedKeys(k: React.Key[]): void {
			dispatch({ type: INFO_SET_EXPAND_KEYS, payload: { expanded: k } });
		},
		setCheckedKeys(k: React.Key[]): void {
			dispatch({ type: INFO_SET_CHECKED_KEYS, payload: { checked: k } });
		},
		setPropsAndExpanded(p: Maybe<IndicatorSpec>, k: React.Key[]): void {
			dispatch({
				type: INFO_SET_PROPS_AND_EXPAND_KEYS,
				payload: {
					props: p,
					expanded: k,
				},
			});
		},
		setActive(uid: string, desc: InfDescription): void {
			dispatch({
				type: INFO_SET_DESCRIPTION,
				payload: {
					desc: {
						...desc,
						idx: uid,
					},
				} as InfoPayload,
			});
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
//#endregion

type StepDataSelectProps = ConnectedProps<typeof connector>;

function StepDataSelect(props: StepDataSelectProps) {
	const {
		properties,
		setCheckedKeys,
		expandedKeys,
		setPropsAndExpanded,
		active,
		setActive,
		desc,
	} = props;
	const dispatch = useDispatch();
	const {
		login: { userId, userData },
	} = useStore<MinimalLoginState>().getState();
	const [indicators, setIndicators] = useState<Indicator[]>();

	// #region active list
	function setActiveAdapter(uid: string) {
		setActive(active === uid ? "" : uid, desc);
	}
	// #endregion

	// #region colors
	const colors: Map<string> = (
		userData ?? { newstanks: [] as Newstank[] }
	).newstanks.reduce((prev, { name, color }) => ({ ...prev, [name]: color }), {});
	// #endregion

	// #region fetching indicators
	useEffect(() => {
		if (!userId) {
			dispatch(loginFailedAction(401));
			return;
		}

		const headers = {
			...AllowOrigin,
			Authorization: userId,
		};

		const url = new URL(`${ROOT_API_URL}/neo/idx/all`);

		fetch(url.href, {
			headers: headers,
		}).then((r) => {
			if (r.ok) {
				r.json().then((v) => {
					setIndicators(v);
				});
			} else {
				if (r.status === 401) {
					dispatch(loginFailedAction(401));
				}
				//TODO: Clemence
			}
		});
	}, []);
	// #endregion

	// #region fetching properties
	useEffect(() => {
		if (!userId) {
			dispatch(loginFailedAction(401));
			return;
		} // should use onError to send error message
		if (!active || active === "") {
			setPropsAndExpanded(undefined, []);
			return;
		}

		const unauth = () => dispatch(loginFailedAction(401));
		getPreviewData(userId, active, expandedKeys, unauth).then((v) => {
			if (!v) {
				// !TODO: handle fetch error
				return;
			}
			const { props, expandedKeys, data } = v;
			setPropsAndExpanded(props, expandedKeys);
			setCheckedKeys(genSelectedKeys(data));
		});
	}, [active]);
	// #endregion

	//#region tree generator
	let treeData: Maybe<{ title: any; key: any; children: any[] }[]> = undefined;
	if (properties) {
		const {
			definition: { title, uid: uuid },
			metas,
		} = properties;
		const children = metas.map(({ labels, props }) => {
			const midTitle = labels
				.split(",")
				.filter((s) => !s.match(/^[A-Z]{3}_/))
				.join(".");
			const children = props
				.filter((s) => !s.name.match(/uid/))
				.map(({ mask, name }) => ({
					title: mask,
					key: `${uuid}.${labels}.${name}`,
				}));
			return {
				title: midTitle,
				key: `${uuid}.${labels}`,
				children,
			};
		});
		treeData = [
			{
				title,
				key: uuid,
				children,
			},
		];
	}

	// #endregion

	return (
		<>
			<Title level={3}>
				À partir de quelles données souhaitez vous construire votre infographie ?
			</Title>
			<Space
				direction="vertical"
				style={{
					width: "100%",
					// height: "100%",
					overflowY: "scroll",
					display: "flex",
					marginTop: "18px",
				}}
			>
				{indicators?.map((indicator) => {
					const { title, lastUpdate, cleanliness, uid, nt, description } =
						indicator;
					return (
						<IndicatorDisplay
							key={_uniqueId("sds-idx-")}
							title={title}
							lastUpdate={lastUpdate}
							cleanliness={cleanliness}
							uid={uid}
							description={description}
							color={colors[nt]}
							active={active === uid}
							setActive={setActiveAdapter}
						/>
					);
				})}
			</Space>
		</>
	);
}

export default connector(StepDataSelect);
