import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Result from '../../Result';
import IndependantWidget from '../Independant';
import {
    CLASSNAMES, DATA_ATTRIBUTES, ERRORS, TOKEN_GENERATOR_TEMPLATE,
    isWidget, testWidget, widgetId, tokenId, classesForInjection,
} from './constants';

interface WindowWithGeData extends Window {
    GEDATA: {
        refresh(): void
        status?: string,
        message?: string,
    }
}

declare let window: WindowWithGeData;

function refresh() {
    console.log("hydrating gedata...");
    // Find all the gedata widget not yet loaded
    const roots = document.getElementsByClassName(CLASSNAMES.GEDATA_NOT_LOADED);
    if (roots.length === 0) {
        console.log("nothing to load");
        return;
    }

    for (let i = 0; i < roots.length; i++) {
        const root = roots.item(i);
        if (!root) continue;

        const wid = root.getAttribute(DATA_ATTRIBUTES.ID);
        const tid = root.getAttribute(DATA_ATTRIBUTES.Token);
        const lang = root.getAttribute(DATA_ATTRIBUTES.Lang) ?? 'fr';

        // create a child div as React root to keep the original data pristine
        const newRoot = document.createElement('div', {});
        newRoot.classList.add(CLASSNAMES.GEDATA_REACT_ROOT);
        root.append(newRoot);

        // remove the classname tagged for rendering
        root.classList.remove(CLASSNAMES.REACT_ROOT_UNLOADED);

        // if no data is missing render the widget
        if (!tid) {
            ReactDOM.render(
                <Result status={403} message={ERRORS.invalidID} />,
                newRoot,
            );
        } else if (!wid) {
            ReactDOM.render(
                <Result status={404} message={ERRORS.invalidResource} />,
                newRoot,
            );
        } else {
            ReactDOM.render(
                <IndependantWidget
                    widgetId={wid ?? undefined}
                    tokenId={tid ?? undefined}
                    lang={lang}
                />,
                newRoot,
            );
        }
    }
    console.log("Done hydrating");
}

function renderWidget() {
    const root = document.getElementById(CLASSNAMES.GEDATA_ROOT);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenId = urlParams.get('tokenId') ?? undefined;
    const widgetId = urlParams.get('widgetId') ?? undefined;
    const lang = urlParams.get('lang') ?? "fr";

    if (!root) {
        console.error("no root found for React!");
        return;
    }

    ReactDOM.render(
        <StrictMode>
            <IndependantWidget
                widgetId={widgetId}
                tokenId={tokenId}
                lang={lang}
            />
        </StrictMode>,
        root
    );
}

export {
    isWidget, testWidget,
    widgetId, tokenId,
    classesForInjection,
    renderWidget,
    TOKEN_GENERATOR_TEMPLATE,
};

export default function injectWidget() {
    window.GEDATA = {
        ...(window.GEDATA ?? {}),
        refresh,
    };
};
