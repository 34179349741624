import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Button, Space, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { Map, Maybe, RSetter } from "../../../interfaces/Utils";
import { saveProject, localDelete } from "./projectHandlers";
import { EmptyProject } from "../interface";
import { openInNewTab } from "../tools";

interface SaveButtonProps {
	saveF: () => void;
}

function SaveButton(props: SaveButtonProps) {
	const { saveF: onClick } = props;
	return (
		<Button style={{ marginTop: 18 }} type="primary" onClick={onClick}>
			Sauvegarder
		</Button>
	);
}

interface DeleteButtonProps {
	projectId: Maybe<string>;
	userId: string;
	setLoading: RSetter<boolean>;
}

function DeleteButton(props: DeleteButtonProps) {
	const { push: navigate } = useHistory();
	const { projectId, userId, setLoading } = props;
	return (
		<Popconfirm
			title="Supprimer le projet ?"
			onConfirm={localDelete(projectId ?? "", userId, navigate, setLoading)}
			disabled={!projectId}
			icon={<QuestionCircleOutlined style={{ color: "red" }} />}
			cancelText="Annuler"
		>
			<Button style={{ marginTop: 18 }} disabled={!projectId} danger>
				Supprimer le projet
			</Button>
		</Popconfirm>
	);
}

interface PreviewButtonProps {
	projectId: Maybe<string>;
	saveF: () => void;
}

function PreviewButton(props: PreviewButtonProps) {
	const { projectId, saveF: onClick } = props;

	if (!projectId) {
		return null;
	}

	return (
		<Button
			style={{ marginTop: 18 }}
			onClick={onClick}
		>
			Previsualiser le projet
		</Button>
	);
}

function urlCreator(lprojectId: string) {
	return `/visualisations/projects/edit/${lprojectId}`;
}

interface ActionButtonsProps {
	projectId: Maybe<string>;
	userId: string;
	project: Maybe<EmptyProject>;
	setLoading: RSetter<boolean>;
}

function ActionButtons(props: ActionButtonsProps) {
	const h = useHistory();
	const { project, projectId, userId, setLoading } = props;
	const waitMessage = projectId
		? "Votre projet a bien été sauvegardé."
		: "Votre projet a bien été sauvegardé, vous allez être redirigé.";
	const delay = projectId ? 100 : 1000;
	const title = project?.info?.private.title;
	const body: Map<any> = { ...project };
	delete body?.name;
	delete body?.description;

	function saveF() {
		saveProject(title, body, userId, h, {
			urlCreator: urlCreator,
			delay: delay,
			waitMessage: waitMessage,
		});
	};

	function saveAndPreview() {
		saveProject(title, body, userId, h, {
			noRedirect: true,
			onOk: (projectId) => openInNewTab(userId, projectId)(),
		});
	}

	return (
		<Space>
			<DeleteButton
				userId={userId}
				projectId={projectId}
				setLoading={setLoading}
			/>
			<PreviewButton projectId={projectId} saveF={saveAndPreview} />
			<SaveButton saveF={saveF} />
		</Space>
	);
}

export default ActionButtons;
