import { message } from "antd";
import moment from "moment";
import { Maybe } from "../../interfaces/Utils";
import { newHeaders, PREVIEW_URL, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { TimedResponse } from "../../store/widget/interfaces";

export function parseSimpleDate(s: string | number): moment.Moment {
	return moment.parseZone(s, "DD/MM/YYYY");
}

export function formatSimpleDate(d: moment.Moment): string {
	return d.format("DD MMMM YYYY");
}

export function parseAndFormatDate(s: string | number): string {
	const out = formatSimpleDate(parseSimpleDate(s));
	return out;
}
type TokenResponse = TimedResponse<{ token: string }, never>;
type TokenAnswer = { status: number, message: string } | { token: string };

async function fetchTokenForPreview(userId: Maybe<string>): Promise<TokenAnswer> {
	if (!userId) {
		return new Promise((resolve) => resolve({
			status: 401,
			message: "ID utilisateur manquante ou invalide",
		}));
	}
	const url = new URL(`${ROOT_API_URL}/api/v1/token/new`);
	const headers = withAuthorization(userId, newHeaders());
	const r = await fetch(url.href, { headers: headers })
	if (!r.ok) {
		return {
			status: r.status,
			message: "erreur dans la récupération du token",
		};
	}
	const { data: { token } }: TokenResponse = await r.json();
	return { token: token };
}

export function openInNewTab(userId: Maybe<string>, projectId: string) {
	return () => fetchTokenForPreview(userId)
		.then(r => {
			if ('status' in r) {
				// !Handle error
				message.error(r.message);
			} else {
				const { token } = r;
				let url: URL;
				if (window.location.hostname === 'localhost') {
					url = new URL(`http://${window.location.host}/visualisations/vue/preview/${projectId}`);
				} else {
					url = new URL(`${PREVIEW_URL}/indicateur/${projectId}/${token}`);
				}
				window.open(url.href, '_blank', "noopener noreferrer");
			}
		});
}
