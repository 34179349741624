import moment from "moment";
import { Map, Maybe } from "../interfaces/Utils";
import { ColumnProps } from "../components/Widget/Table/columns";
import { EditorialDates } from "../interfaces/editorial";
import { Sorting } from "./search";
import { Clause } from "./filters";
import { Indicator } from "../components/Indicators/Management/interface";

export type Params = Map<any>;

export interface Aggregation {
	key: string;
	doc_count: number;
}
export interface Suggestions {
	index: string;
	key: string;
	values: Aggregation[];
}

export const defaultSuggestions: Suggestions = {
	index: "",
	key: "",
	values: [],
};

export interface Key extends ColumnProps {
	data_type: string;
	search_type: string;
	display_format: string;
	mask?: string;
}

export interface AllParams extends Params {
	search: Map<any>;
}

export default interface Vue {
	id: string;
	title: string;
	type: string;
	params: AllParams;
	default_columns?: string[];
	filtered_columns?: string[];
	keys: Map<Key>;
	rawView?: View;
	rawIdx?: Indicator;
	data_view?: string;
}

export interface VueData {
	count: number;
	hits: Map<any>[];
}

export interface EditorialInfo {
	title: [string, boolean];
	description: [string, boolean];
}

export interface MetaData {
	sources?: string;
	author?: string;
	dates?: EditorialDates;
}

export const defaultMetaData: MetaData = {
	sources: "",
};

export type DataKey = "header" | "footer" | "sources";

export function updateMaybeMetaData(
	me: Maybe<MetaData>,
	key: DataKey,
	value: string
): MetaData {
	return {
		...(me ?? defaultMetaData),
		[key]: value,
	};
}

export interface Info {
	title: string;
	description?: string;
}

export const defaultInfo: Info = { title: "", description: "" };

export type MetaAccess = "private" | "public";

export type MetaKey = "title" | "description";

export function updateMaybeInfo(inf: Maybe<Info>, value: string, key: MetaKey): Info {
	return { ...(inf ?? defaultInfo), [key]: value };
}

export interface MetaInfo {
	public?: Info;
	private: Info;
}

export const defaultMetaInfo: MetaInfo = { private: { title: "", description: "" } };

export function updateMaybeMeta(
	mi: Maybe<MetaInfo>,
	value: Info,
	key: MetaAccess
): MetaInfo {
	return { ...(mi ?? defaultMetaInfo), [key]: value };
}

export function getInfo(mi: Maybe<MetaInfo>, access: MetaAccess): Maybe<Info> {
	return mi ? mi[access] : undefined;
}

export function updateMaybeBoth(
	mi: Maybe<MetaInfo>,
	outer: MetaAccess,
	inner: MetaKey,
	value: string
): MetaInfo {
	return updateMaybeMeta(mi, updateMaybeInfo(getInfo(mi, outer), value, inner), outer);
}

export interface View {
	doc_type: string;
	uid: string;
	parent_uid: string;
	slug: string;
	title: string;
	type: string;
	news_tank: string;
	comment: string;
	default_columns?: string[];
	filtered_columns?: string[];
	idx: string;
	search_params: any;
	dates: EditorialDates;
	info: MetaInfo;
	// dynamic?: boolean;
	meta?: MetaData;
	sorting?: Sorting;
	filters?: Clause[][];
	static?: moment.Moment;
	data_view?: string;
}

export interface SaveView {
	doc_type?: string;
	uid?: string;
	parent_uid: string;
	slug?: string;
	type: string;
	news_tank?: string;
	filtered_columns?: string[];
	default_columns?: string[];
	idx: string;
	search_params: any;
	dates?: EditorialDates;
	info: MetaInfo;
	// dynamic?: boolean;
	static?: string;
	meta?: MetaData;
	sorting?: Sorting;
	filters?: Clause[][];
}
