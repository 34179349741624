import { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch, useStore } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { Button, Card, Select, Result as AntResult, Spin } from "antd";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";

import { CloseAction } from "../../../../store/sidebar";
import { MinimalLoginState } from "../../../../store/login/interface";
import { CurrentEditStore, fetchedAndSelAction, loadingAction } from "../store";

import "../../../../css/tools/elements.css";
import { LabelTitle, TitleFormat } from "../titleFormatter";
import TitleElement from "./TitleElement";
import CloseButton from "./CloseButton";
import {
	NodeToSorted,
	saveAction,
	setSelected,
	SortedToOption,
	sortUnsortedData,
} from "./mergeNodes";
import { NodeMergeProps, NodeMergeState, SortedData } from "./interface";
import {
	cardStyle,
	ERR_NO_ENOUGH_DATA,
	ERR_NO_TITLE_FOR_LABEL,
} from "../common/constants";
import { NodeFetch } from "../../interface";
import { nodefetcher } from "../common/linefetch";
import { uncurry2 } from "../../../../lib/lens";
import MaybeSpinner from "../../../tools/MaybeSpinner";

// #region connect to Node Fetch store
interface StateProps {
	fetched: NodeFetch;
}

function mapStateToProps({ edit: { fetched }, edit }: CurrentEditStore): StateProps {
	return {
		fetched: fetched,
	};
}

interface DispatchProps {
	setFetched(e: NodeFetch): void;
}
function mapDispatchTopProps(
	dispatch: ThunkDispatch<CurrentEditStore, void, any>
): DispatchProps {
	return {
		setFetched: function setFetched(fetched: NodeFetch): void {
			dispatch(fetchedAndSelAction(fetched));
		},
	};
}
const connector = connect(mapStateToProps, mapDispatchTopProps);
// #endregion

type Props = NodeMergeProps & ConnectedProps<typeof connector>;

function NodeMerge(props: Props) {
	const {
		login: { userId: mbUserId },
	} = useStore<MinimalLoginState>().getState();
	const {
		data: { mergeData, labels },
		fetched,
		setFetched,
	} = props;
	const dispatch = useDispatch();
	const [state, setState] = useState<NodeMergeState>({});

	if (!mbUserId) {
		return <div>Vous devez être authentifié pour effectuer cette action.</div>;
	}
	const userId = mbUserId;

	const label = labels.filter((l) => !l.match(/^IDX_/)).join(" - ");
	const indicator = labels.find((l) => l.match(/^IDX_/));
	if (!label || !indicator) {
		return <div>Vos données sont corrompues, veuillez recharger la page.</div>;
	}

	useEffect(() => {
		if (mergeData.length < 2) {
			setState({ err: ERR_NO_ENOUGH_DATA });
			return;
		}

		const labelTitle = LabelTitle[label];
		if (!labelTitle) {
			setState({ err: ERR_NO_TITLE_FOR_LABEL });
			return;
		}

		const mapper = TitleFormat[label];
		const mapF = NodeToSorted(mapper);
		const sorted: SortedData[] = mergeData.map(mapF).sort(sortUnsortedData);
		const selected = sorted[0].uid;
		setState({
			data: {
				selected: selected,
				sorted: sorted,
				labelTitle: labelTitle,
			},
		});
	}, [mergeData, label]);

	const closeSider = () => {
		dispatch(CloseAction);
		dispatch(loadingAction(false));
	};

	const cardTitle = (
		<span
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "flex-start",
				height: "60px",
			}}
		>
			Fusion
			<Button type="link" onClick={closeSider}>
				<CloseOutlined />
			</Button>
		</span>
	);

	// #region buttons
	const { err, data } = state;
	if (err !== undefined || data === undefined) {
		console.log(err, data);
		return (
			<AntResult
				icon={<SmileOutlined />}
				title={err || "Une erreur s'est produite"}
				extra={<CloseButton onOk={() => dispatch(loadingAction(false))} />}
			/>
		);
	}
	const { selected } = data;
	const saveBtnAction = () => {
		setState({ ...state, loading: true });
		saveAction(selected, mergeData, userId, labels, () => {
			// force search refresh in edit store, as we have access to this there
			setTimeout(() => {
				nodefetcher(labels, fetched, setFetched, userId);
				setState({ ...state, loading: false });
				dispatch(CloseAction);
				dispatch(loadingAction(false));
			}, 1000);
		})();
	};
	const cardActions = [
		<div
			style={{
				textAlign: "right",
				height: "32px",
			}}
		>
			<Button style={{ marginRight: 8, bottom: "0" }} onClick={closeSider}>
				Annuler
			</Button>
			<Button type="primary" onClick={saveBtnAction}>
				Sauvegarder
			</Button>
		</div>,
	];
	// #endregion

	const { labelTitle, sorted } = data;
	const options = sorted.map(SortedToOption);
	const realSetSelected = setSelected(state);

	const card = (
		<Card bordered={false} title={cardTitle} style={cardStyle} actions={cardActions}>
			<TitleElement title={labelTitle} />
			<Select
				value={selected}
				onChange={uncurry2(realSetSelected, setState)}
				style={{ width: 300 }}
			>
				{options}
			</Select>
		</Card>
	);
	return <MaybeSpinner loading={state.loading}>{card}</MaybeSpinner>;
}

export default connector(NodeMerge);
