import moment from "moment";
import { uniqueId as _uniqueId } from "lodash";

import { Card, Button, Row, Typography, Divider, Space } from "antd";

import { inBetween } from "../../../lib/array";
import { EditorialDates, ExtendedToString } from "../../../interfaces/editorial";
import TagViewGenerator from "../../tools/TagViewGenerator";
import { Project } from "../interface";
import ProjectActions from "../ProjectActions";

import "../../../css/projects/list.css";
import "../../../css/tools/elements.css";

const { Paragraph, Text } = Typography;

const projectDescError = "Ce projet n'a pas de description, merci d'en ajouter une.";

interface ProjectDisplayProps {
	project: Project;
}

export function dateLine(dates: EditorialDates, authors: string[], direction: 'column' | 'row' = 'row'): JSX.Element[] {
	const { published, created, edited } = dates;

	const authorsComponents = authors.join(" - ");

	const datesLines: JSX.Element[] = [];
	if (created) {
		datesLines.push(
			<>
				Créé le {ExtendedToString(created)} par {authorsComponents}
			</>
		);
	}
	if (edited) {
		datesLines.push(<>Edité {ExtendedToString(edited)}</>);
	}
	if (published) {
		datesLines.push(<>Publié {ExtendedToString(published)}</>);
	}

	return direction === 'row'
		? inBetween(datesLines, <> - </>)
		: datesLines.map(e => <div>{e}</div>)
}

function ProjectDisplay(props: ProjectDisplayProps) {
	const key = _uniqueId("folded-project-card-");
	const { project } = props;
	const {
		uid: id,
		authors,
		data_view: dv,
		dates: { published },
		info,
		dates,
		used_views: uv,
	} = project;
	const defaultView = !!dv && dv.length > 0;
	const pub = published
		? moment.parseZone(published.date).isBefore(moment.now())
		: false;
	const usedViews = uv ? uv.length : 0;
	const {
		private: { title, description },
	} = info;
	const typeTag = defaultView ? (
		<TagViewGenerator type="rubrique" />
	) : (
		<TagViewGenerator type="article" />
	);
	const statusTag = pub ? (
		<TagViewGenerator type="published" />
	) : (
		<TagViewGenerator type="draft" />
	);
	return (
		<Card
			id={`card-${id}`}
			className="project-mini-display"
			style={{
				padding: "6px 6px 0px 6px",
				marginBottom: "12px",
			}}
			size="small"
			key={key}
		>
			<Row justify="space-between">
				<Button
					size="small"
					className="project-title nicer-link"
					type="link"
					href={`/visualisations/projects/edit/${id}`}
				>
					{title}
				</Button>
				<Space>
					{statusTag}
					{typeTag}
				</Space>
			</Row>
			<Divider />
			<Row justify="space-between">
				<span>
					<Text style={{ color: "rgba(0, 0, 0, 0.75)" }}>Thématiques: </Text>
					<Button className="nicer-link" type="link" size="small">
						Sponsoring
					</Button>
				</span>
				<Button
					type="link"
					size="small"
					disabled={!(usedViews > 0)}
					href={`/visualisations/projects/edit/${id}#views`}
				>
					{usedViews} vue
					{usedViews > 1 ? "s" : " "} dans le projet
				</Button>
			</Row>
			<Divider />
			<Paragraph ellipsis={{ rows: 3, expandable: false }}>
				{description || projectDescError}
			</Paragraph>
			<ProjectActions projectId={id} dates={dates} authors={authors} />
		</Card>
	);
}

export default ProjectDisplay;
