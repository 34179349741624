import handlerMap from "../handlers";

export interface ColumnProps {
	title: string;
	type: string;
	dataIndex: string;
	defaultView?: boolean;
	alwaysShown?: boolean;
	renderArg?: any;
	filters?: any;
	onFilter?: any;
	data_type?: string;
}

export default function Columns(props: ColumnProps, i: number) {
	const { title, dataIndex, type } = props;
	const col = handlerMap[type];
	if (col !== undefined) {
		return col(props, i);
	}
	return {
		title: title,
		dataIndex: dataIndex,
		key: i,
		render: (e: any) => e,
	};
}
