import { message } from "antd";

import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../lib/fetch";
import { RSetter } from "../../../interfaces/Utils";
import { ProjectBuilderData } from "./interfaces";

function removeVue(
	id: string,
	vueId: string,
	userId: string,
	state: ProjectBuilderData,
	setState: RSetter<ProjectBuilderData>
): () => void {
	return () => {
		const method = "PATCH";
		const body = JSON.stringify({
			id: id,
			params: {
				used_views: {
					action: "remove",
					value: vueId,
				},
			},
		});
		const headers = withAuthorization(userId, newHeaders());
		const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
		const index = state.project?.used_views?.indexOf(vueId) ?? -1;
		const nviews = [...(state.project?.used_views ?? [])];
		if (index !== -1) {
			nviews.splice(index, 1);
		}
		fetch(url.href, { method: method, body: body, headers: headers }).then((r) => {
			if (!r.ok) {
				message.error(`Erreur lors du retrait de la vue: ${r.status}`);
				return;
			}
			setState({
				...(state ?? {}),
				project: {
					...state.project,
					used_views: nviews,
				},
			});
			message.success(
				"La vue a correctement été correctement retirée de la liste."
			);
		});
	};
}

export { removeVue };
