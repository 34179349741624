import { Popconfirm, Space, Typography } from "antd";
import moment from "moment";
import { Maybe } from "../../../../interfaces/Utils";
import explorer, { setter } from "../../../../lib/explorer";
import { Callbackize } from "../../../../lib/functions";
import { Item } from "../interface";

import { ItemDef, KeyParent, NestedCol } from "./interfaces";

function getDate(v: any): Maybe<moment.Moment> {
	if (!moment.isMoment(v) && v !== undefined) {
		v = moment.parseZone(v);
	}
	return moment.isMoment(v) ? v : undefined;
}

type CelledItem = ItemDef & {
	onCell?: any;
	width?: number;
};

function addEditActionsCol(
	id: string,
	columns: any[],
	roots: KeyParent[],
	editingKey: React.Key,
	isEditing: (record: Item) => boolean,
	save: (key: React.Key) => void,
	cancel: () => void,
	edit: (record: Item) => void,
	dup: (record: Item) => void,
	remove: (record: Item) => void,
	restore: (record: Item) => void,
) {
	const cols = roots.reduce<NestedCol[]>((acc, cur) => {
		const { name: pname, prefix, children } = cur;
		const defs: CelledItem[] = children.map((c) => {
			const { name: cname, mask, type, fixed, values, priority } = c;
			let dataIndex = `${prefix}.${cname}`;
			// eslint-disable-next-line no-underscore-dangle
			const existing = columns?.find(
				(col) => col._dataIndex === dataIndex || col.title === c.mask
			);
			return {
				...(existing ?? {}),
				title: mask,
				editable: true,
				type: type,
				priority: priority,
				parent: pname,
				fixed: fixed,
				onCell: (record: Item) => {
					const editing = isEditing(record);
					if (!editing) {
						return {
							record: record,
							dataIndex: dataIndex,
							type: type,
							title: mask,
							priority: priority,
							parent: pname,
							editing: editing,
							values: values,
						};
					}
					if (type === "date") {
						const path = dataIndex.split(".");
						const value = explorer(path, record);
						const date = getDate(value);
						setter(path, record, date);
					} else {
						let v = explorer(dataIndex, record);

						if (v === undefined) {
							let cleanedIndex = dataIndex;
							if (dataIndex.match(/\.(fr|en)$/)) {
								cleanedIndex = dataIndex.replace(/\.(fr|en)$/, "");
							} else if (
								dataIndex.endsWith(".name") &&
								// !Do not remove!
								// To prevent removing `name` from non-nested
								// objects like `organization.name` which would be
								// empty on new objects ; the create a new key
								// `organization` and not ['organization`, `name`]
								dataIndex.indexOf(".") !== dataIndex.lastIndexOf(".")
							) {
								cleanedIndex = dataIndex.substring(
									0,
									dataIndex.length - 5
								);
							}
							v = explorer(cleanedIndex, record);
							if (Array.isArray(v)) {
								setter(cleanedIndex, record, v.join(", "));
							}
							dataIndex = cleanedIndex;
						}
					}
					return {
						record: record,
						dataIndex: dataIndex,
						type: type,
						title: mask,
						priority: priority,
						_dataIndex: `${prefix}.${cname}`,
						parent: pname,
						editing: editing,
						values: values,
						refId: id,
					};
				},
			} as ItemDef;
		});
		return defs.length > 1 ? [...acc, ...defs] : [...acc, defs[0]];
	}, []);
	cols.push({
		title: "Actions",
		dataIndex: "operation",
		fixed: "right",
		width: 220,
		render: (_: any, record: Item) => {
			const editable = isEditing(record);
			const { _deleted, _created } = record;
			const deleted = _created !== undefined && _deleted !== undefined;
			// eslint-disable-next-line no-nested-ternary
			return editable ? (
				<td
					key="operation"
					className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first"
					style={{ position: "sticky", right: "0px" }}
				>
					<Space>
						<a
							href="javascript:;"
							onClick={Callbackize(save, record.key)}
							style={{ marginRight: 8 }}
						>
							Sauvegarder
						</a>
						<Typography.Link onClick={cancel}>Annuler</Typography.Link>
					</Space>
				</td>
			) : deleted ? (
				<td
					key="operation"
					className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first"
					style={{ position: "sticky", right: "0px" }}
				>
					<Space>
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={Callbackize(restore, record)}
						>
							Restaurer
						</Typography.Link>
					</Space>
				</td>
			) : (
				<td
					key="operation"
					className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-first"
					style={{ position: "sticky", right: "0px" }}
				>
					<Space>
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={Callbackize(edit, record)}
						>
							Éditer
						</Typography.Link>
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={Callbackize(dup, record)}
						>
							Dupliquer
						</Typography.Link>
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={Callbackize(remove, record)}
						>
							Supprimer
						</Typography.Link>
					</Space>
				</td>
			);
		},
	});
	return cols;
}

export default addEditActionsCol;
