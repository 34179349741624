import { useState } from "react";
import { useStore } from "react-redux";
import moment from "moment";

import { AutoComplete, DatePicker, Form, Input, InputNumber, Select } from "antd";
import locale from "antd/lib/date-picker/locale/fr_FR";

import { newHeaders, withAuthorization, ROOT_API_URL } from "../../../../lib/fetch";
import { Aggregation } from "../../../../model/vue";
import { MinimalLoginState } from "../../../../store/login/interface";
import { Priority, PRIO_REQUIRED } from "../../../Layout/Sidebar/PropEdit/constants";
import { Maybe, RSetter } from "../../../../interfaces/Utils";

import "../../../../css/indicators/ingest.css";
import explorer from "../../../../lib/explorer";
import { RuleType } from "rc-field-form/lib/interface";
import { Rule } from "antd/lib/form";
import TagSelectWithSuggestion from "../../TagSelectWithSuggestion";

const arrayPrefix = "array:";

const ARRAY = Object.freeze({
	prefix: arrayPrefix,
	string: arrayPrefix + "string",
});

function getDate(dataIndex: string, record: any, lang = "fr"): string {
	let v = explorer(dataIndex.split("."), record) ?? record[dataIndex];
	if (!moment.isMoment(v) && v !== undefined) {
		v = moment.parseZone(v);
	}
	if (!moment.isMoment(v)) {
		if (v === undefined) {
			return "";
		}
		return lang === "en" ? "Invalid date" : "Date invalide";
	}
	return v.format(lang === "fr" ? "DD MMM YYYY" : "YYYY/MM/DD");
}

interface Option {
	label: string;
	value: string;
	key: React.Key;
}

function mapAggregations(aggs: Aggregation[]): Option[] {
	const ret = new Array(aggs.length);
	for (let i = 0; i < aggs.length; i++) {
		const { key, doc_count } = aggs[i];
		const label = doc_count > 0 ? `${key} (${doc_count})` : key;
		ret[i] = { value: key, key: key, label: label };
	}
	return ret;
}

function onChangeC(
	userId: Maybe<string>,
	refId: string,
	dataIndex: string,
	setAutoCompleteResult: RSetter<Aggregation[]>
): (value: string) => void {
	return function (value: string): void {
		if (!userId) {
			console.error("no user id");
			return;
		}
		if (!value) {
			setAutoCompleteResult([]);
			return;
		}
		const headers = withAuthorization(userId, newHeaders());
		const url = new URL(`${ROOT_API_URL}/api/v1/suggs`);
			if (refId !== undefined) {
			url.searchParams.append("id", refId);
		}
		url.searchParams.append("key", dataIndex);
		url.searchParams.append("value", value);
		fetch(url.href, { headers: headers }).then((r) => {
			if (!r.ok) {
				console.error(r);
			} else {
				r.json().then((v: Aggregation[]) => {
					setAutoCompleteResult(v);
				});
			}
		});
	};
}

interface EditableCellProps<Item> extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	type: string;
	record: Item;
	index: number;
	children: React.ReactNode;
	priority: Priority;
	refId: string;
	parent: string;
	values?: string[];
}

function EditableCell<Item>(props: EditableCellProps<Item>) {
	const {
		priority, editing, dataIndex, type, children, title, refId, record, values,
		...restProps
	} = props;
	const [autoCompleteResult, setAutoCompleteResult] = useState<Aggregation[]>([]);
	const {
		login: { userId },
	}: MinimalLoginState = useStore().getState();
	const onChange = onChangeC(userId, refId, dataIndex, setAutoCompleteResult);
	const options = mapAggregations(autoCompleteResult);

	let inputNode: JSX.Element;
	switch (type) {
		case "number":
			inputNode = <InputNumber decimalSeparator="," />;
			break;
		case "date":
			inputNode = (
				<DatePicker
					locale={locale}
					format="DD/MM/YYYY"
					style={{ width: "100%" }}
				/>
			);
			break;
		default:
			if (!type) {
				return <>{children}</>;
			}
			if (type.startsWith(ARRAY.prefix) && type === ARRAY.string) {
				if (values) {
				const options = values?.map((v) => ({ label: v, value: v }));
				inputNode = <Select mode="multiple" options={options} />;
				} else {
					inputNode = <TagSelectWithSuggestion
						route={""}
						userId={userId}
						searchKey={dataIndex}
					/>
				}
				break;
			}
			inputNode = (
				<AutoComplete
					options={options}
					onChange={onChange}
					placeholder={title}
					dropdownMatchSelectWidth={false}
					dropdownClassName="ingest-dropdown"
				>
					<Input />
				</AutoComplete>
			);
	}

	const rules: Rule[] = [];
	if (priority === PRIO_REQUIRED) {
		rules.push({ required: true, message: `Ce champ ne peut être vide` });
	}
	if (["number", "string", "date"].indexOf(type) !== -1) {
		rules.push({ type: type as RuleType });
	}
	const rdataIndex = dataIndex.split(".");
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={rdataIndex}
					style={{ margin: 0 }}
					rules={rules}
				>
					{inputNode}
				</Form.Item>
			) : (type === "date" ? getDate(dataIndex, record) : children)}
		</td>
	);
}

export default EditableCell;
