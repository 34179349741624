import { Typography } from "antd";

import explorer from "../../../../lib/explorer";
import getDomainColor from "../../../tools/getDomainColor";
import { ColumnProps } from "../columns";

import "../../../../css/widget/table/index.css";

const { Paragraph } = Typography;

const defaultTextHandler = (props: ColumnProps, i: number) => {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}
	const rem = k.slice(1);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		key: i,
		// width: renderArg?.width ?? "",
		width: "",
		// sorter: sortHandler(dataIndex),
		render: (value: any, _rec: any) => {
			let data: any;
			if (!!rem) {
				data = explorer(rem, value);
				if (!data && renderArg?.alternateIndex) {
					let keys: string[];
					if (typeof renderArg.alternateIndex === "string") {
						keys = [renderArg.alternateIndex];
					} else {
						keys = renderArg.alternateIndex as string[];
					}

					for (let i = 0; i < keys.length; i++) {
						const key = keys[i];
						data = explorer(key, value);
						if (!!data && data !== "") break;
					}
				}
			} else {
				data = value;
			}

			return renderArg?.defaultValue &&
				renderArg?.hideDefault &&
				data === renderArg.defaultValue ? (
				<span />
			) : (
				<Paragraph
					className="table-paragraph"
					ellipsis={{
						expandable: true,
						rows: 3,
						symbol: (
							<span style={{ color: getDomainColor(false) }}>plus</span>
						),
					}}
				>
					{data}
				</Paragraph>
			);
		},
	};
};

export default defaultTextHandler;
