import { SortOrder } from "antd/lib/table/interface";
import { Maybe } from "../interfaces/Utils";

const orderings = ['desc', 'descend', 'descending'];

export type Sorting = { key: string, ordering: 'asc' | 'desc' };
export function newSorting(key?: string, ordering?: string | SortOrder): Maybe<Sorting> {
	if (!key) return undefined;
	return ({
		key,
		ordering: (orderings.indexOf(ordering ?? "") !== -1) ? 'desc' : 'asc',
	});
}

export type SearchParam = any[];

export type SearchParams = {
	[key: string]: SearchParam,
} & {
	_all: SearchParam,
};

function each(p: Maybe<SearchParams>, f: (k: string, values: SearchParam) => void) {
	if (!p) return;
	for (let k in p) f(k, p[k]);
}

export const defaultSearchParams: SearchParams = { _all: [], };

export interface Pagination {
	page: number,
	size: number,
}

export const defaultPaginationSize = 20;

export const defaultPagination: Pagination = { page: 1, size: defaultPaginationSize, };
export const newPagination = (page: number, size?: number): Pagination =>
	({ page, size: size ?? defaultPagination.size });

export default interface Search {
	sorting?: Sorting,
	pagination?: Pagination,
	params?: SearchParams,
}

function stringify(v: any): string {
	if (!v?.constructor?.name) return "";
	switch (v.constructor.name) {
		case "Moment":
			return (v as moment.Moment).format("YYYY-MM-DDThh:mm:ss")+'Z';
		case "Array":
			return (v as any[]).map(stringify).join(',');
		default:
			return v.toString();
	}
}

export function  createSearchParams(s: Search): URLSearchParams {
	const sp = new URLSearchParams();
	each(s.params, (k, v) => v.forEach(e => sp.append(k, stringify(e))));
	const pagination = s.pagination ?? defaultPagination;
	const { page, size } = pagination;
	if (page !== 1) sp.append("_page[offset]", '' + page);
	if (size !== defaultPaginationSize) sp.append("_page[limit]", '' + size);

	if (s.sorting) {
		const { key, ordering, } = s.sorting;
		sp.append("_sort[key]", key);
		sp.append("_sort[value]", ordering);
	}
	return sp;
}
