import { useState } from "react";

import _uniqueId from "lodash/uniqueId";
import { Row, Col, Typography, Anchor, Space } from "antd";
import {
	TableOutlined,
	BarChartOutlined,
	DotChartOutlined,
	PieChartOutlined,
	LineChartOutlined,
} from "@ant-design/icons";

import VisSelector from "./VisSelector";
import vis from "./visList";

const { Title } = Typography;
const { Link } = Anchor;

//missing: maps, sankey, network, Slope, Gantt, Tree (radial, )

function StepVisSelect() {

	return (
		<>
			<Row justify="space-between">
				<Title level={3}>Quel type d'infographie souhaitez-vous créer ?</Title>
				{/* <Button onClick={}>
					Bonne question, je ne sais pas trop ...
				</Button> */}
			</Row>
			<Space align="start">
				<Row
					style={{
						height: "100%",
						overflowY: "scroll",
						display: "flex",
						justifyContent: "space-around",
						marginRight: 20,
						paddingLeft: 20,
					}}
				>
					{vis.map(({ idTable, visIcon, visTitle, visChildren }) => {
						return (
							<VisSelector
								key={_uniqueId("vis-select-")}
								idTable={idTable}
								visIcon={visIcon}
								visTitle={visTitle}
								visChildren={visChildren}
								// selectAction={}
							/>
						);
					})}
				</Row>
				<Anchor style={{ width: 36, position: "absolute", right: "1rem" }}>
					<Link
						href="#table"
						title={
							<TableOutlined className="Infographics-StepCreate-icons-anchor" />
						}
					/>
					<Link
						href="#pie"
						title={
							<PieChartOutlined className="Infographics-StepCreate-icons-anchor" />
						}
					/>
					<Link
						href="#bar"
						title={
							<BarChartOutlined className="Infographics-StepCreate-icons-anchor" />
						}
					/>
					<Link
						href="#line"
						title={
							<LineChartOutlined className="Infographics-StepCreate-icons-anchor" />
						}
					/>
					<Link
						href="#dot"
						title={
							<DotChartOutlined className="Infographics-StepCreate-icons-anchor" />
						}
					/>
					{/* <Link href="#area" title={<AreaChartOutlined className="Infographics-StepCreate-icons-anchor" />} /> */}
					{/* <Link href="#radar" title={<RadarChartOutlined className="Infographics-StepCreate-icons-anchor" />} /> */}
					{/* <Link href="#box" title={<BoxPlotOutlined className="Infographics-StepCreate-icons-anchor" />} /> */}
				</Anchor>
			</Space>
		</>
	);
}

export default StepVisSelect;
