import { Typography } from "antd";

const { Title } = Typography;

interface TitleElementProps {
	title: string;
}

export default function TitleElement(props: TitleElementProps) {
	const { title } = props;
	return <Title level={5}>{title}</Title>;
}
