import _uniqueId from "lodash/uniqueId";

import { Card, Progress, Row, Typography } from "antd";

// import SetFavorite from "../UserPrefs/SetIndicatorFavorite";
import IndicatorActions from "../Home/IndicatorActions";
import { Link } from "react-router-dom";

const { Paragraph } = Typography;
const { Meta } = Card;

const defaultDescription = (title: string) => (
	<div>
		Merci de renseigner une description pour permettre à chacun de comprendre les
		usages et enjeux du <i>{title}</i>.
	</div>
);

interface IndicatorDisplayProps {
	title: string;
	cleanliness: number;
	uid: string;
	description: string;
	color: string;
	home?: boolean;
	active?: boolean;
	lastUpdate?: string;
	setActive?: (uid: string) => void;
}

function IndicatorDisplay(props: IndicatorDisplayProps) {
	const {
		title,
		lastUpdate,
		cleanliness,
		uid,
		description,
		home,
		color,
		active,
		setActive,
	} = props;
	const homeV = home ?? false;
	return (
		<Card
			key={_uniqueId("idx-card-")}
			// hoverable
			size="small"
			style={{
				maxWidth: "100%",
				border: !homeV && active ? "3px solid #d3adf7" : undefined,
				borderLeft: `6px solid ${color}`,
				padding: "8px 4px 4px 8px",
			}}
			className="HomeIndicators-card"
			onClick={() => {
				if (!homeV && !!setActive) {
					setActive(uid);
				}
			}}
		>
			<Meta
				/* REACTIVATE */
				// avatar={
				// 	<Progress
				// 		type="circle"
				// 		className="HomeIndicators-progress"
				// 		percent={cleanliness}
				// 		width={45}
				// 	/>
				// }
				title={
					<Row justify="space-between" style={{ marginBottom: 6 }}>
						<Link to={`/indicators/edit/${uid}`} className="nicer-link">
							{title}
						</Link>
						{/* REACTIVATE */}
						{/* <SetFavorite /> */}
					</Row>
				}
			/>
			<Paragraph
				ellipsis={{ rows: 3, expandable: false }}
				style={{ color: "rgba(0, 0, 0, 0.70)" }}
			>
				{description || defaultDescription(title)}
			</Paragraph>
			{home === true ? (
				<IndicatorActions indicatorId={uid} lastUpdate={lastUpdate ?? ""} />
			) : undefined}
		</Card>
	);
}

export default IndicatorDisplay;
