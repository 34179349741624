import { useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import _ from "lodash";

import { Button, Form, Modal, Space, Typography } from "antd";

import { Callbackize } from "../../../../lib/functions";
import { filterActionSet, InfoState } from "../../../../store/infographics";

import { Clause, DataType, Filter } from "../../../../model/filters";
import { Map, Maybe } from "../../../../interfaces/Utils";
import FilterWrapper from "./FilterAnd";

const { Text } = Typography;

const dataTypePairs: Map<DataType> = {
	"organization.name": "string",
	"league.name": "string",
	"contract.season": "number",
	"contract.begin": "string",
	"contract.sponsorship_status.fr": "string",
	"contract.sponsorship_status.en": "string",
	"contract.visibility.fr": "string",
	"contract.visibility.en": "string",
	"contract.duration": "number",
	"contract.end": "string",
	"contract.news_link": "string",
	"contract.amount": "number",
	"contract.level": "number",
	"contract.officialisation": "date",
	"contract.marketing_manager": "string",
	"contract.category.name.fr": "array:string",
	"contract.category.name.en": "array:string",
	"sponsor.name": "string",
	"sponsor.sector.name.fr": "array:string",
	"sponsor.sector.name.en": "array:string",
};

export const dataTypeMasks: Map<string> = {
	"organization.name": "Club",
	"league.name": "Championnat",
	"contract.season": "Saison",
	"contract.begin": "Début",
	"contract.sponsorship_status.fr": "Statut",
	"contract.sponsorship_status.en": "Status",
	"contract.visibility.fr": "Visibilité",
	"contract.visibility.en": "Visibility",
	"contract.duration": "Durée",
	"contract.end": "Fin",
	"contract.news_link": "Source",
	"contract.amount": "Montant (en million(s) d'euros)",
	"contract.level": "Niveau",
	"contract.officialisation": "Date d'officialisation",
	"contract.marketing_manager": "Régie marketing",
	"contract.category.name.fr": "Type 🇫🇷",
	"contract.category.name.en": "Type 🇬🇧/🇺🇸",
	"sponsor.name": "Sponsor",
	"sponsor.sector.name.fr": "Secteur d'activité",
	"sponsor.sector.name.en": "Sector",
};

export const emptyClause: Clause = { include: true };

interface StateProps {
	filter: Filter;
}

function mapStateToProps(state: { info: InfoState }): StateProps {
	const {
		info: { filter },
	} = state;

	return {
		filter: filter ?? {},
	};
}

interface DispatchProps {
	setFilter: (filter: Filter) => void;
}
function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setFilter: (filter: Filter) => {
			dispatch(filterActionSet(filter));
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {}

function DataSourceFilter(props: Props) {
	const { filter, setFilter } = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const clauses = filter?.clauses;
	const [form] = Form.useForm<{ ands: Clause[][] }>();

	// #region FilterModal
	const showModal = Callbackize(setIsModalVisible, true);
	const closeModal = Callbackize(setIsModalVisible, false);
	// #endregion

	const title = "Ajouter un filtre";
	const footer = [
		<Button key="back" onClick={closeModal}>
			Annuler
		</Button>,
		<Button key="use" type="primary" onClick={form.submit}>
			Sauvegarder
		</Button>,
	];

	function onEnd(clauses: Maybe<Clause[][]>) {
		setFilter({ clauses: clauses });
		closeModal();
	}

	const closeRef = useRef(onEnd);
	return (
		<>
			<Button onClick={showModal}>Filtrer les sources</Button>
			<Modal
				title={title}
				style={{ minWidth: 1100 }}
				visible={isModalVisible}
				onOk={closeModal}
				onCancel={closeModal}
				footer={footer}
				maskClosable={false}
			>
				<Space direction="vertical" style={{ height: 600, overflowY: "scroll" }}>
					<FilterWrapper
						dataTypePairs={dataTypePairs}
						values={clauses}
						form={form}
						closeRef={closeRef}
					/>
				</Space>
			</Modal>
		</>
	);
}

export default connector(DataSourceFilter);
