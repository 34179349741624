import { useState, useRef, MutableRefObject } from "react";

import { Button, Modal } from "antd";
import { TableOutlined } from "@ant-design/icons";

import _ from "lodash";

import { EditState } from "../interface";
import HerePaste from "./HerePaste";

import { Callbackize } from "../../../../lib/functions";
import { Map, RSetter } from "../../../../interfaces/Utils";

interface AddDataProps {
    headers: { title: string, dataIndex: string }[];
    keys: Map<{ data_type: string, title: string }>;
    editState: MutableRefObject<[EditState, RSetter<EditState>]>;
    arrays: Map<string>;
    requiredValues: string[];
    title: string;
}

function AddData(props: AddDataProps) {
    const { headers, keys, editState, arrays, requiredValues, title } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const setVisibleRef = useRef(setVisible);
    return (
        <div >
            <Modal
                title={"Ajouter depuis Excel"}
                visible={visible}
                footer={null}
                onCancel={Callbackize(setVisible, false)}
                destroyOnClose
            >
                <HerePaste
                    headers={headers}
                    keys={keys}
                    setVisibleRef={setVisibleRef}
                    editState={editState}
                    arrays={arrays}
                    requiredValues={requiredValues}
                    title={title}
                />
            </Modal>
            <Button onClick={Callbackize(setVisible, true)}>
                <TableOutlined />Importer
            </Button>
        </div >
    );
}

export default AddData;
