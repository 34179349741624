import { Map } from "../../../interfaces/Utils";
import { IndicatorData } from "./interface";

export interface IndicatorMoreData {
    created: string;
    authors: string[];
    population: string;
    labels?: string[][];
    cleanliness?: IndicatorData;
}

const indicatorData: Map<IndicatorMoreData> = {
    IDX_SPT_DEALTRACKER: {
        authors: ["Hugo SAVY"],
        created: "04/04/2022",
        population: "Contrat",
    },
    IDX_BOAMP: {
        authors: ["Clémence BERGON"],
        created: "28/10/2022",
        population: "Offre de marché",
        labels: [
            ["MP_Emetteur", "IDX_BOAMP"],
            ["MP_Deal", "IDX_BOAMP"],
            ["Location", "IDX_BOAMP"],
            ["Domaine", "IDX_BOAMP"],
            ["Famille", "IDX_BOAMP"],
            ["Procedure", "IDX_BOAMP"]
        ],
        cleanliness: {
            global: 0,
            labels: [
                { global: 0, count: 0, stats: {}, labels: ["MP_Emetteur", "IDX_BOAMP"] },
                { global: 0, count: 0, stats: {}, labels: ["MP_Deal", "IDX_BOAMP"] },
                { global: 0, count: 0, stats: {}, labels: ["Location", "IDX_BOAMP"] },
                { global: 0, count: 0, stats: {}, labels: ["Domaine", "IDX_BOAMP"] },
                { global: 0, count: 0, stats: {}, labels: ["Famille", "IDX_BOAMP"] },
                { global: 0, count: 0, stats: {}, labels: ["Procedure", "IDX_BOAMP"] },
            ]
        }
    }
}

export default indicatorData;
