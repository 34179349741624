import moment from "moment";

import explorer from "../../../../lib/explorer";
import { ColumnProps } from "../columns";

function defaultDateHandler(props: ColumnProps, i: number) {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type: dataType } = props;
	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}

	const rem = k.slice(1);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: dataType,
		editable: true,
		key: i,
		align: "center",
		// width: renderArg?.width ?? "",
		width: "",
		// sorter: sortHandler(dataIndex),
		render: (value: any) => {
			const date = k.length > 1 ? explorer(rem, value) : value;
			if (!date) {
				return "";
			}
			return moment(date).locale("fr").format("DD/MM/YYYY");
		},
	};
}

export default defaultDateHandler;
