import { noop } from "lodash";
import _uniqueId from "lodash/uniqueId";
import { createElement } from "react";

import {
	Button,
	Card,
	Col,
	Divider,
	Popconfirm,
	Row,
	Space,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import { InfoCircleOutlined, ThunderboltTwoTone, EditTwoTone } from "@ant-design/icons";

import { labels as labelsMapping } from "../../tools/mappings";
import { IndicatorData } from "./interface";
import { Maybe } from "../../../interfaces/Utils";

import "../../../css/indicators/management.css";
import "../../../css/tools/elements.css";
import reIndex from "../Label/common/reindex";

const { Paragraph } = Typography;

const cleanables = ["Sector"];

interface ActionButtonProps {
	text: string;
	disabled?: boolean;
	href: string;
	icon: typeof EditTwoTone;
}

function ActionButton(props: ActionButtonProps) {
	const { href, text, disabled, icon } = props;

	const iconElement = createElement(icon, {
		twoToneColor: "#9254de",
		style: { marginRight: 3 },
	});
	const disabled_ = disabled ?? false;
	return (
		<Button
			className="discrete-link"
			type="link"
			href={disabled_ ? undefined : href}
			disabled={disabled_}
		>
			{iconElement}
			{text}
		</Button>
	);
}

interface CardStructureProps {
	id: string;
	userId: Maybe<string>;
	cleanliness: Maybe<IndicatorData>;
	labels: Maybe<string[][]>;
}

function CardStructure(props: CardStructureProps) {
	const { id, userId, cleanliness, labels } = props;

	if (!cleanliness) {
		return <Spin>Chargement de données supplémentaires</Spin>;
	}

	function refreshAll() {
		const data = {
			count: labels?.length,
			got: [] as string[][],
		};
		console.log("Debut de rafraichissement de", labels);
		labels?.forEach(l => {
			const name = l.sort().join(".");
			console.log(`Reindexation de ${name} commencée.`)
			reIndex(() => {
				if (data.got.findIndex(e => JSON.stringify(l) === JSON.stringify(e)) === -1) {
					data.got.push(l);
				}
				if (data.got.length === data.count) {
					console.log("all is refreshed !");
				} else {
					console.log(`${name} est à jour`);
				}
			}, l, noop, userId)();
		});
	};

	const ignoreList = ["done"];
	return (
		<Card
			className="card-structure"
			title={
				<Row justify="space-between">
					<span>
						Structure de l&apos;indicateur
						<Tooltip
							placement="right"
							title="Représente les entités distinctes d'une population de données."
						>
							<InfoCircleOutlined
								style={{ marginLeft: 6, color: "rgba(0, 0, 0, 0.45" }}
							/>
						</Tooltip>
					</span>
					<Popconfirm
						title="Forcer le rafraichissement des données"
						okText="Oui"
						cancelText="Non"
					>
						<Button onClick={refreshAll}>
							Rafraichir les données
						</Button>
					</Popconfirm>
				</Row>
			}
		// REACTIVATE
		// extra={
		// 	<Button type="default" href={`/indicators/edit/spec/${id}`}>
		// 		Paramétrer la structure
		// 	</Button>
		// }
		>
			<div>
				{/* <Row justify="space-between">
					<Col span={10}>Indicateur global</Col>
					<Col>
						<span style={{ fontWeight: 'bold' }}>{cleanliness.global} occurrences</span>
					</Col>
					<Col>
						<div style={{ width: 170 }}>
							<Progress percent={Math.trunc(cleanliness.global)} />
						</div>
					</Col>
				</Row> */}
				<Row gutter={0} justify="space-between">
					<Col span={10}>
						<Paragraph strong>Nature des données</Paragraph>
					</Col>
					<Col>
						<Paragraph strong>Actions</Paragraph>
					</Col>
				</Row>
				{cleanliness.labels
					.sort((a, b) => a.count - b.count)
					.reverse()
					.map(({ labels, count }) => {
						let title = labels
							.filter((val) => !/[A-Z]{3}_/g.test(val))
							.join(" - ");
						const cleanable = cleanables.indexOf(title) !== -1;
						title = labelsMapping[title] ?? title;
						// if (title === "ContractType") {
						// 	return;
						// }
						return (
							<Row
								gutter={0}
								justify="space-between"
								key={_uniqueId("label-row-")}
								style={{
									fontWeight: title === "Contrats" ? "bold" : undefined,
								}}
							>
								<Col span={10}>
									<span style={{ marginRight: 5 }}>{title}</span>(
									{count})
								</Col>
								{/*
									<Col>
										{Object.keys(stats)
											.filter((k) => ignoreList.indexOf(k) === -1)
											.filter((k) => {
												return k === "_avg";
											})
											.map((k) => {
												const value = stats[k];
												return (
													<div
														style={{ width: 170 }}
														key={_uniqueId("stats-")}
													>
														<Progress percent={Math.trunc(value)} />
													</div>
												);
											})}
									</Col> 
								*/}
								<Col>
									{/* REACTIVATE */}
									{/*
										<Link
											to={{
												pathname: "/indicators/label/edit",
												search: labels.map(l => `label=${l}`).join("&"),
											}}
											className="discrete-link"
										>
											<EditTwoTone twoToneColor="#9254de" style={{ marginRight: 3 }} />
											Éditer
										</Link>
									*/}
									<Space>
										{title === "Contrats" ? null : (
											<ActionButton
												href={`/indicators/label/clean?${labels
													.map((l) => `label=${l}`)
													.join("&")}`}
												text="Nettoyer"
												disabled={!cleanable}
												icon={ThunderboltTwoTone}
											/>
										)}
										<ActionButton
											href={`/indicators/label/edit?${labels
												.map((l) => `label=${l}`)
												.join("&")}`}
											text="Éditer"
											// disabled
											icon={EditTwoTone}
										/>
									</Space>
								</Col>
								<Divider style={{ margin: 4 }} />
							</Row>
						);
					})}
			</div>
		</Card>
	);
}

export default CardStructure;
