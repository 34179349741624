import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { AllowOrigin, ROOT_API_URL } from '../../lib/fetch';

import { confStore, DataWidget } from '.';
import Result from '../Result';
import { LoginState } from '../../store/login/interface';

interface InnerState {
	error?: { status: number, message?: string } | string;
	userId?: string;
	loading: boolean;
}

interface IndependantWidgetProps {
	widgetId?: string;
	tokenId?: string;
	lang: string;
}

function IndependantWidget(props: IndependantWidgetProps) {
	const { widgetId, tokenId, lang } = props;
	const [state, setState] = useState<InnerState>({ loading: true });

	useEffect(() => {
		const url = new URL(ROOT_API_URL + "/webservice/token");
		fetch(url.href, {
			method: "POST",
			body: JSON.stringify({ token: tokenId }),
			headers: AllowOrigin,
		})
			.catch(_ => {
				return ({
					ok: false,
					json: async () => ({}),
					status: 403,
					statusText: "Unauthorized",
				} as {
					ok: boolean,
					json(): Promise<any>,
					status: number,
					statusText: string,
				});
			})
			.then(async r => {
				if (r.ok) {
					const j = await r.json();
					if (!j.sesh) {
						setState({
							loading: false,
							error: { status: 403, message: "Forbidden" },
						});
					} else {
						setState({ loading: false, userId: j.sesh, });
					}
				} else {
					setState({
						loading: false,
						error: { status: 403, message: r.statusText },
					});
				}
			})
	}, []);

	if (!widgetId || !tokenId) {
		return (<Result status={403} />);
	} else if (state.error) {
		if (typeof state.error === "string") {
			return (<Result status={404} message={state.error} />);
		}
		return (<Result status={state.error.status as any} message={state.error.message} />);
	} else if (!state.userId) {
		return (<Result status={403} message="Unauthorized" />);
	}

	const loginStore: LoginState = {
		userId: state.userId,
	}
	const newStore = confStore({ login: loginStore });

	return (
		<Provider store={newStore}>
			<DataWidget
				id={widgetId}
				userId={state.userId}
				color="#722ed1"
				lang={lang}
			/>
		</Provider>
	);
}

export default IndependantWidget;