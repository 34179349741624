import { useState } from "react";
import { useStore } from "react-redux";

import { AutoComplete, Checkbox, DatePicker, InputNumber, Space, Switch } from "antd";
import locale from "antd/lib/date-picker/locale/fr_FR";

import moment from "moment";

import { Maybe, RSetter } from "../../../../interfaces/Utils";
import { DataType } from "../../../../model/filters";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../../../lib/fetch";

function reMoment(v: any): moment.Moment {
	return moment.isMoment(v) ? v : moment.parseZone(v);
}

function fetchOptions(
	field: Maybe<string>,
	userId: Maybe<string>,
	setOptions: RSetter<{ value: string }[]>,
): ((value: string) => void) {
	return async function (value) {
		if (!field || !userId) {
			return;
		}
		const url = new URL(ROOT_API_URL + "/api/v1/suggs");
		url.searchParams.append("key", field);
		url.searchParams.append("value", value);
		const headers = withAuthorization(userId, newHeaders());
		const r = await fetch(url.href, { headers: headers });
		if (!r.ok) {
			console.error(r);
			return;
		}
		const b: { key: string; doc_count: number; }[] = await r.json();
		setOptions(b.map(({ key }) => ({ value: key })));
	};
}

interface FilterInputProps {
	dataType: Maybe<DataType>;
	op: Maybe<string>;
	onChange?: (v: any) => void;
	value?: any;
	field?: string;
}

function FilterInput(props: FilterInputProps) {
	const userId: Maybe<string> = useStore().getState()?.login?.userId;
	const [options, setOptions] = useState<{ value: string }[]>([]);
	const { dataType, op, onChange, value, field } = props;
	switch (dataType) {
		case "string":
		case "array:string":
			switch (op) {
				case "contains":
				case "equalTo":
				case "strictEqualTo":
				case "startsWith":
				case "endsWith":
				case "in":
					return (
						<AutoComplete
							value={value}
							onSelect={v => onChange?.(v)}
							onSearch={fetchOptions(field, userId, setOptions)}
							onChange={v => onChange?.(v)}
							options={options}
							style={{ width: 325 }}
						/>
					);
				case "isEmpty":
					return (
						<Checkbox
							value={value}
							onChange={(e) => onChange?.(e.target.checked)}
						/>
					);
				default:
					console.log(`invalid op: \`${op}\``);
					return <div>Opérateur invalide pour ce type de colonne</div>;
			}
		case "number":
		case "array:number":
			switch (op) {
				case "equalTo":
				case "superiorOrEqualTo":
				case "superiorTo":
				case "inferiorOrEqualTo":
				case "inferiorTo":
					return (
						<InputNumber
							style={{ width: 325 }}
							value={value as number}
							onChange={(e) => onChange?.(e)}
							decimalSeparator=","
						/>
					);
				case "between":
					const [left, right] = value && Array.isArray(value) ? value : [];
					return (
						<Space split="ET">
							<InputNumber
								value={left as number}
								onChange={(e) => onChange?.([e, right])}
								style={{ width: 146 }}
								decimalSeparator=","
							/>
							<InputNumber
								value={right as number}
								onChange={(e) => onChange?.([left, e])}
								style={{ width: 146 }}
								decimalSeparator=","
							/>
						</Space>
					);
				case "isEmpty":
					return (
						<Switch
							size="small"
							checked={value}
							checkedChildren="Oui"
							unCheckedChildren="Non"
							onChange={(ch) => onChange?.(ch)}
						/>
					);
				default:
					console.log(`invalid op: \`${op}\``);
					return <div>Opérateur invalide pour ce type de colonne</div>;
			}
		case "date":
			switch (op) {
				case "equalTo":
				case "before":
				case "after":
					const v = reMoment(value);
					return (
						<DatePicker
							format="DD/MM/YYYY"
							locale={locale}
							value={v}
							onChange={(e) => onChange?.(e)}
						/>
					);
				case "between":
					const [l, r] = value && Array.isArray(value) ? value : [];
					const left = reMoment(l);
					const right = reMoment(r);
					return (
						<Space>
							<DatePicker
								format="DD/MM/YYYY"
								locale={locale}
								value={left}
								onChange={(e) => onChange?.([e, right])}
							/>
							<DatePicker
								format="DD/MM/YYYY"
								locale={locale}
								value={right}
								onChange={(e) => onChange?.([left, e])}
							/>
						</Space>
					);
				default:
					console.log(`invalid op: \`${op}\``);
					return <div>Opérateur invalide pour ce type de colonne</div>;
			}
		default:
			console.log(`unhandled type: \`${dataType}\``);
			return <div>Type non reconnu ou implementé</div>;
	}
}

export default FilterInput;
