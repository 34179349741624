import { Tag } from "antd";
import { dedup } from "../../../../lib/functions";

import { ColumnProps } from "../columns";
import { getAllValues } from "./stringArray";

const basicColorMap = [
	{
		value_fr: "Nouveau",
		value_en: "New",
		color: "green",
	},
	{
		value_fr: "Prolongation",
		value_en: "Renewal",
		color: "gold",
	},
	{
		value_fr: "Extension",
		value_en: "Extension",
		color: "lime",
	},
	{
		value_fr: "Renégociation",
		value_en: "Renegotiation",
		color: "magenta",
	},
	{
		value_fr: "Fin",
		value_en: "End",
		color: "red",
	},
	{
		value_fr: "Diminution",
		value_en: "Decrease",
		color: "volcano",
	},
	// {
	// 	value_fr: "",
	// 	value_en: "",
	// 	color: "orange",
	// },
	// 	{
	// 		value_fr: "",
	// 		value_en: "",
	// 		color: "cyan",
	// 	},
	// 	{
	// 		value_fr: "",
	// 		value_en: "",
	// 		color: "blue",
	// 	},
	// 	{
	// 		value_fr: "",
	// 		value_en: "",
	// 		color: "geekblue",
	// 	},
	// 	{
	// 		value_fr: "",
	// 		value_en: "",
	// 		color: "purple",
	//  },
	// 		value_fr: "",
	// 		value_en: "",
	// 		color: "pink",
	// 	},
];

export type TagType = string | number | (string | number)[];

function canShowTag(renderArg: any): (value: string | number) => boolean {
	return function (value: string | number): boolean {
		if (
			renderArg?.defaultValue &&
			value === renderArg.defaultValue &&
			renderArg?.hideDefault
		) {
			return false;
		}
		return true;
	};
}

function asTag(colorMap: any, color: any): (value: string | number) => JSX.Element {
	return (t) => {
		const tagColor = colorMap
			? colorMap.find((e: any) => e.value_fr === t)?.color
			: color;
		return (
			<Tag color={tagColor ?? "geekblue"} key={t} style={{ fontSize: 14 }}>
				{t}
			</Tag>
		);
	};
}

export function renderTags(types: TagType, renderArg: any, colorMap: any, color: any) {
	return Array.isArray(types)
		? types.filter(canShowTag(renderArg)).map(asTag(colorMap, color))
		: canShowTag(renderArg)(types)
			? asTag(colorMap, color)(types)
			: null;
}

function tagsHandler(props: ColumnProps, i: number) {
	const { title, dataIndex, renderArg } = props;
	const { type, data_type } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}

	const rem = k.slice(1);
	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		// editable: true,
		key: i,
		// onFilter: onFilter ? interprete(onFilter) : undefined,
		// filters: filters,
		align: "center",
		// width: renderArg?.width ?? "",
		width: "",
		render: (value: any) => {

			const colorMap =
				renderArg !== undefined && "colorMap" in renderArg
					? renderArg.colorMap
					: basicColorMap;
			const color =
				renderArg !== undefined && "color" in renderArg
					? renderArg.color
					: "purple";
			if (value === undefined) {
				return <></>;
			}
			let types = getAllValues(value, rem);
			if (Array.isArray(types)) {
				types = dedup(types);
			}
			return types ? <>{renderTags(types, renderArg, colorMap, color)}</> : null;
		},
	};
}

export default tagsHandler;
