

import _uniqueId from "lodash/uniqueId";
import { History } from "history";

import MiniPreview from "./Home/MiniPreview";
import { Maybe, RSetter } from "../../interfaces/Utils";
import { View } from "../../model/vue";
import MustHaveParam from "./MustHaveParam";
import InfographicsList from "./InfographicsList";
import { useStore } from "react-redux";
import { useHistory } from "react-router";
import { Button, message, Popconfirm } from "antd";
import { newHeaders, ROOT_API_URL, withAuthorization } from "../../lib/fetch";
import { EyeOutlined, ForkOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function useView(
	id: string,
	userId: string,
	histo: History<any>,
	projectId: Maybe<string>,
	setLoading?: (v: boolean) => void
) {
	if (!projectId) {
		// !TODO: What to do ?
		message.error("ID du projet manquante.");
		return;
	}
	const method = "PATCH";
	const body = JSON.stringify({
		id: projectId,
		params: {
			used_views: {
				action: "append",
				value: id,
			},
		},
	});

	const headers = withAuthorization(userId, newHeaders());
	const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
	if (setLoading) {
		setLoading(true);
	}
	fetch(url.href, { headers: headers, method: method, body: body }).then((r) => {
		if (!r.ok) {
			message.error(`Erreur (${r.status}): erreur lors de l'ajout de la vue`);
			if (setLoading) {
				setLoading(false);
			}
		} else {
			message.success(
				`La vue a correctement été ajoutée, vous allez être redirigé.`
			);
			setTimeout(() => {
				histo.push(`/visualisations/projects/edit/${projectId}`);
			}, 1000);
		}
	});
}

function dupView(_id: string, _userId: string) {
	message.warning("Pas encore implementé pour le moment.");
}

function getActions(
	hideAdvancedActions: Maybe<boolean>,
	id: string,
	projectId: Maybe<string>,
	userId: string,
	histo: History<any>,
	setLoading?: (v: boolean) => void
): JSX.Element[] {
	const actions = [
		<Popconfirm
			title="Utilisation"
			okText="Utiliser"
			cancelText="Dupliquer"
			onConfirm={() => useView(id, userId, histo, projectId, setLoading)}
			onCancel={() => dupView(id, userId)}
		>
			<Button>
				<ForkOutlined />
				Utiliser
			</Button>
		</Popconfirm>,
	];

	if (!hideAdvancedActions) {
		actions.push(
			<Link to="/visualisations">
				<InfoCircleOutlined />
				<p>Détail</p>
			</Link>
		);
		actions.push(
			<a
				href={`/visualisations/preview/${id}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<EyeOutlined />
				<p>Aperçu Front</p>
			</a>
		);
	}
	return actions;
}

function miniPreviewWrapper(projectId: string) {
	return function (setLoading: RSetter<boolean>) {
		return function (view: View) {
			const histo = useHistory()
			const {
				info: {
					private: { title, description },
				},
				uid,
				news_tank,
				idx,
				type,
				static: staticV,
				// dynamic,
			} = view;
			const actions = getActions(
				true,
				uid,
				projectId,
				useStore().getState()?.login?.userId,
				histo,
				setLoading
			);

			return (
				<MiniPreview
					key={_uniqueId("view-")}
					id={uid}
					title={title}
					description={description}
					indicator={idx}
					nt={news_tank}
					type={type}
					// isDefault={isDefault /** TODO: Fix it properly */}
					isStatic={!!staticV}
					dates={view?.meta?.dates}
					author={view?.meta?.author}
					actions={actions}
				/>
			);
		};
	}
}

function InfographicsSelect() {
	return (
		<MustHaveParam
			paramKey="projectId"
			subTitle="Nous avons besoin d'un projet auquel le rattacher."
		>
			{(projectId) => {
				const wrapper = miniPreviewWrapper(projectId);
				return (
					<InfographicsList>
						{(data, setLoading) => {
							const loadingWrapper = wrapper(setLoading);
							return data.map(loadingWrapper);
						}}
					</InfographicsList>
				);
			}}
		</MustHaveParam>
	);
}

export default InfographicsSelect;
