import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect, ConnectedProps, useStore } from "react-redux";
import { combineReducers, Dispatch } from "@reduxjs/toolkit";

import { Button, Result, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { Maybe } from "../../interfaces/Utils";
import infoReducer, {
	InfDescription, InfoAction, InfoPayload, InfoState, INFO_SET_EDIT_VUE, INFO_SET_LOADING,
} from "../../store/infographics";
import loginReducer, { loginFailedAction } from "../../store/login";
import sidebarReducer from "../../store/sidebar";
import { searchReducer } from "../../store/widget";
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../lib/fetch";
import { Fetched, TimedResponse } from "../../store/widget/interfaces";
import { getStringParam, useQueryParams } from "../../lib/get_params";
import { View } from "../../model/vue";
import { genSelectedKeys, getPreviewData, getTriple } from ".";
import { IndicatorSpec } from "../Indicators/interface";
import StateInterceptor from "./StateInterceptor";
import { VueTriple } from "./interface";

//#region Store connection
interface StateProps {
	vue: InfDescription;
	expandedKeys: React.Key[];
	loading?: boolean;
}

function mapStateToProps(props: { info: InfoState }): StateProps {
	const { info: { desc, expanded, loading } } = props;
	return { vue: desc, expandedKeys: expanded, loading: loading };
}

interface DispatchProps {
	failedAction(code: number, message?: string): void;
	setEditData(vue: View, triple: VueTriple, checked: React.Key[], p: Maybe<IndicatorSpec>, expanded: React.Key[]): void;
	setLoading: (v: boolean) => void;
	// setVue(vue: View, triple: VueTriple): void;
	// setCheckedKeys(): void;
	// setPropsAndExpanded(): void;
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
	return {
		failedAction: function failedAction(code: number, message?: string): void {
			dispatch(loginFailedAction(code, message));
		},
		setEditData: function setEditData(
			vue: View,
			triple: VueTriple,
			checked: React.Key[],
			spec: Maybe<IndicatorSpec>,
			expanded: React.Key[],
		) {
			dispatch({
				type: INFO_SET_EDIT_VUE,
				payload: {
					desc: vue,
					vueTriple: triple,
					checked: checked,
					props: spec,
					expanded: expanded,
					step: 2,
					loading: false,
				} as InfoPayload
			} as InfoAction)
		},
		setLoading: function setLoading(v: boolean): void {
			dispatch({ type: INFO_SET_LOADING, payload: { loading: v } });
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
//#endregion

type InfographicsNewProps = ConnectedProps<typeof connector>;

function RInfographicsNew(props: InfographicsNewProps) {
	const {
		loading, setLoading,
		vue,
		failedAction,
		expandedKeys,
		setEditData,
	} = props;
	const store = useStore();
	const urlParams = useParams<{ vue?: string, id?: string }>();
	const { vue: vueId, id: rvueId } = urlParams;
	const params = useQueryParams();
	const projectId = getStringParam(params, "projectId");

	useEffect(() => {
		if (projectId && (vueId || rvueId)) {
			const id = vueId ?? rvueId ?? "";
			const url = new URL(`${ROOT_API_URL}/api/v1/fetch/vue`);
			url.searchParams.append("id", id);
			const userId: Maybe<string> = store.getState().login?.userId;
			if (!userId) {
				store.dispatch(loginFailedAction(401));
				return;
			}
			const headers = withAuthorization(userId, newHeaders());
			setLoading(true);
			fetch(url.href, { headers, })
				.then(async r => {
					if (r.ok) {
						const b: TimedResponse<Fetched<View[]>, never> = await r.json();
						if (b.data.data.length > 0) {
							const vue = b.data.data[0];
							const indexUid = vue?.idx;
							if (!indexUid) {
								console.error("no idx ID");
								// !TODO: throw big error
								return;
							}

							Promise.all([
								getPreviewData(userId, indexUid, expandedKeys, () => { }),
								getTriple(indexUid, userId),

							])
								.then(pl => {
									const [pdp, trp] = pl;
									if (!trp || !pdp) {
										// !TODO: handle error
										return;
									}
									const { props, expandedKeys, data } = pdp;
									const checked = genSelectedKeys(data);
									setEditData(vue, trp, checked, props, expandedKeys);
								});
						}
						return;
					}
					failedAction(r.status, r.statusText);
				})
		}
	}, []);

	if (!projectId) {
		return (
			<Result
				icon={<WarningOutlined />}
				title={"Il manque l'ID du projet auquel la rattacher."}
				extra={<Button href="/visualisations/projects/list">Retour aux projets</Button>}
			/>
		);
	}

	let inner = (<StateInterceptor />);
	if ((!!vueId && !vue) || loading) {
		return (<Spin>{inner}</Spin>);
	} else {
		return inner;
	}
};

const InfographicsNew = connector(RInfographicsNew);

function InfographicsCreator() {
	const store = useStore();

	store.replaceReducer(combineReducers({
		login: loginReducer,
		sidebar: sidebarReducer,
		info: infoReducer,
		widget: searchReducer,
	}));

	return <InfographicsNew />;
}

// export default connector(InfographicsNew);
export default InfographicsCreator;
