import { connect, ConnectedProps } from "react-redux";

import { Steps } from "antd";

import { Maybe } from "../../interfaces/Utils";
import { Callbackize } from "../../lib/functions";
import {
	InfDescription,
	InfoState,
	INFO_SET_DESCRIPTION,
	INFO_SET_STEP,
} from "../../store/infographics";
import StepButton from "./InfoStepperButton";

const { Step } = Steps;
// #endregion

// #region step interface
export interface DescStatePair<T> {
	state: T;
	setState: (React.Dispatch<React.SetStateAction<T>> | ((v: T) => void));
	onSuccess: () => void;
}

export interface StepProps {
	title: string;
	description?: string;
	content: React.ReactNode;
}

// #region Stepper Store connexion
interface StateProps {
	step: number;
	desc: InfDescription;
}

function mapStateToProps(state: { info: InfoState }): StateProps {
	const {
		info: { step, desc },
	} = state;
	return {
		step: step ?? 0,
		desc: desc,
	}
}

interface DispatchProps {
	setDesc: (desc: Maybe<InfDescription>) => void;
	setStep: (step: number) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		setDesc: function setDesc(desc: Maybe<InfDescription>): void {
			dispatch({ type: INFO_SET_DESCRIPTION, payload: { desc: desc } });
		},
		setStep: function setStep(step: number): void {
			dispatch({ type: INFO_SET_STEP, payload: { step: step } });
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

const stepsStyle = {
	padding: "0px 24px 24px 24px",
};

const stepperStyle = {
	display: "flex",
	flexDirection: "column" as const,
	justifyContent: "space-between",
};

const stepperWrapperStyle = {
	height: "calc(100vh - 104px - 24px - 32px - 24px - 2rem - 40px)",
	overflowY: "scroll" as const,
};

const stepperButtonWrapperStyle = {
	display: "flex" as const,
	justifyContent: "flex-end" as const,
	position: "absolute" as const,
	bottom: "1rem",
	right: "1rem",
};

interface StepperProps extends ConnectedProps<typeof connector> {
	steps: (pair: DescStatePair<InfDescription>) => StepProps[];
	successMessage: string;
	errorMessage: string;
	onFinish?: () => void;
}

function Stepper(props: StepperProps) {
	const {
		step: current,
		setStep,
		steps,
		desc,
		setDesc,
		// successMessage, errorMessage,
		onFinish,
	} = props;

	const next = Callbackize(setStep, current + 1);

	const realSteps = steps({ state: desc, setState: setDesc, onSuccess: next, });
	return (
		<div style={stepperStyle}>
			<div>
				<Steps style={stepsStyle} current={current}>
					{realSteps.map((item) => (
						<Step
							key={item.title}
							description={item.description}
							title={item.title}
						/>
					))}
				</Steps>
				<div style={stepperWrapperStyle}>{realSteps[current].content}</div>
			</div>
			<div style={stepperButtonWrapperStyle}>
				<StepButton steps={3} onFinish={onFinish} />
			</div>
		</div>
	);
}

export default connector(Stepper);
