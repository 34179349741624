import { ReactNode, useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import moment from "moment";
import _ from "lodash";
import _uniqueId from "lodash/uniqueId";

import { Form, Table } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { SearchOutlined } from "@ant-design/icons";

import getDomainColor from "../../tools/getDomainColor";
import { PRIO_REQUIRED } from "../../Layout/Sidebar/PropEdit/constants";
import { composeLens } from "../../../lib/lens";
import { wrap } from "../../../lib/strings";
import { Map, Maybe } from "../../../interfaces/Utils";
import { defaultPagination } from "../../../interfaces/searchParams";
import { Serie, TimeSerie } from "../../../model/series";
import Vue, { Suggestions, VueData } from "../../../model/vue";
import Search, { defaultSearchParams, newPagination } from "../../../model/search";
import { paramsLens, allLens as _allLens, paginationLens } from "../lenses";
import SearchDisplayer from "../SearchDisplayer";
import UniqueSearchBar from "../UniqueSearchBar";
import { Checkable, EditionStatus, EDITION_STATUSES, EditState, Item } from "./interface";
import genOnChange from "./onChange";
import MoreColumns from "./MoreColumns";
import { DatePicker, FullText, Tags } from "./filters";
import Columns from "./columns";
import calcSummary from "./display/Summary";
import ViewInfo from "./display/ViewInfo";
import genFirstCols from "./columns/genFirstCols";
import EditableCell from "./edit/EditableCell";
import addEditActionsCol from "./edit/EditActions";
import { KeyDescription, KeyParent } from "./edit/interfaces";
import get from "../../Indicators/indicatorDefinitions"
import HeaderButtons from "./edit/HeaderButtons";
import { CurrentEditStore } from "../../Indicators/Label/store";
import { merge, reduce } from "../../../lib/functions";
import enTranslations from "./translate/map";

function getRowClassname(record: Map<any>) {
	const { _created, _edited, _deleted } = record;
	let newClassName = "";
	if (_created === undefined) {
		newClassName = "table-row-new";
	} else if (_deleted !== undefined) {
		newClassName = "table-row-deleted";
	} else if (_edited !== undefined) {
		newClassName = "table-row-edit";
	}
	return newClassName;
}

function getContainer(className: string) {
	return function (trigger: HTMLElement) {
		return (document.querySelector(`.${className}`) as HTMLElement) ?? trigger;
	};
}
const getSorterContainer = getContainer("gedata-sorter-container");
const getTooltipContainer = getContainer("gedata-tooltip-container");

interface MaybeFormProps {
	// eslint-disable-next-line react/require-default-props
	form?: FormInstance<any>;
	children: Maybe<ReactNode[] | ReactNode>;
}

function MaybeForm(props: MaybeFormProps) {
	const { form, children } = props;

	if (!form) {
		if (children) {
			if (Array.isArray(children)) {
				// eslint-disable-next-line react/jsx-no-useless-fragment
				return <>{children}</>;
			}
			return children as JSX.Element;
		}
		return null;
	}
	return (
		<Form form={form} component={false}>
			{children}
		</Form>
	);
}

function removeLocalization(strs: string[]): string[] {
	return strs.map((c) => c.replaceAll(/\.(name\.)?(fr|en)$/g, ""));
}

const defaultWidgetTableProps = {
	editable: false,
	showCols: true,
	hideInfo: false,
	lang: "fr",
};

function prepareData(hits: Map<any>[], def: KeyParent[]): Item[] {
	return hits.map((v, i) => {
		const o: Item = {
			key: i,
			__status: EDITION_STATUSES.EXISTING,
		};
		for (let key in v) {
			const e = v[key];
			const ldef = def.find((d) => d.prefix === key);
			if (ldef !== undefined) {
				for (let child of ldef.children) {
					const { name, type } = child;
					const elem = e[name.replace(".name", "")];
					if (type === "date") {
						e[name] = moment.parseZone(elem);
					}
				}
			}
			o[key] = e;
		}
		return o;
	});
}

type WidgetTableProps = {
	id: string;
	color: string;
	vue: Vue;
	data: VueData;
	loading: boolean;
	search: Search;
	updateSearch(s: Search): void;
	suggestions: Suggestions;
	updateSuggestions: (index: string, key: string) => void;
	showCols?: boolean;
	editable?: boolean;
	hideInfo?: boolean;
	lang?: string;
} & typeof defaultWidgetTableProps;

function WidgetTable(props: WidgetTableProps) {
	const {
		id,
		data,
		vue,
		loading,
		search,
		updateSearch,
		suggestions,
		updateSuggestions,
		color,
		showCols,
		editable,
		hideInfo,
		lang,
	} = props;
	const requiredValues = reduce(
		vue.rawIdx?.ops.neo.push.keys ?? {},
		(k, v) => ([k, v.required ?? false]),
	);
	const {
		login: { userId },
	}: CurrentEditStore = useStore().getState();

	const defCols = removeLocalization(vue.default_columns ?? []);
	const [form] = useForm();
	const [state, setState] = useState<EditState>({
		editedData: editable ? prepareData(data.hits, get(vue.rawView?.idx) ?? []) : [],
		editingKey: "",
		saving: false,
	});
	const upperColsRef = useRef([] as { title: string, dataIndex: string }[]);

	// When editable, the table must modify and keep the modified/new values
	// but change the other ones
	useEffect(() => {
		if (!editable) {
			return;
		}
		setState({ ...state, saving: true });
		const toKeep: any[] = [];
		for (let i = 0; i < editedData.length; i++) {
			const e = editedData[i];
			const { _created, _edited, _deleted } = e;
			// Data tagged for suppression
			if (
				_deleted !== undefined ||
				// Newly created line
				_created === undefined ||
				// Data already existing but modified
				_edited !== undefined
			) {
				toKeep.push(e);
			}
		}
		const keyed = data.hits.map((d, i) => ({ ...d, key: `data-fetch-${i}` }));
		const newData = toKeep.length > 0 ? [...toKeep, ...keyed] : keyed;
		setState({
			...state,
			editedData: newData,
			saving: false,
		});
	}, [editable, data.hits]);

	const { editingKey, editedData, oldValue, saving } = state;
	const { pagination } = search;
	const allKeys = Object.keys(vue.keys);
	const { page, size } = pagination ?? defaultPagination;
	const [keys, setKeys] = useState(
		genFirstCols(defCols, vue.keys, vue.rawView?.filtered_columns ?? [])
	);

	if (!vue.params) {
		vue.params = { search: {} };
	}
	let cols = keys
		.filter(({ checked }) => checked || editable)
		.map((col) => col.key)
		.map((e) => ({ ...vue.keys[e], dataIndex: e }));
	if (lang === 'en') {
		cols = cols.map((c) => ({
			...c,
			title: enTranslations[c.title],
		}));
	}
	const titlesMap = Object.keys(vue.keys).reduce(
		(prev, curr) => {
			const title = vue.keys[curr].title;
			return ({
				...prev,
				[curr]: lang === 'en' ? (enTranslations[title] ?? title) : title,
			})
		},
		{} as Map<string>
	);

	const elements: JSX.Element[] = [];
	const allLens = composeLens(paramsLens, _allLens);
	const all = wrap(allLens.get(search) ?? []);
	function setAll(s: string[]) {
		const s1 = allLens.set(search, s);
		const s2 = paginationLens.set(s1, defaultPagination);
		updateSearch(s2);
	}

	if (!vue.params.hideGlobalSearch) {
		elements.push(
			<UniqueSearchBar
				key={_uniqueId("usb-")}
				all={all}
				setAll={setAll}
				lang={lang}
			/>
		);
	}

	function asCheckable(keys: string[], checked: boolean): Checkable[] {
		return keys.map((key) => ({ key: key, checked: checked }));
	}

	const defaultChecked = [
		...asCheckable(defCols, true),
		...asCheckable(
			allKeys
				.filter((k) => defCols.indexOf(k) === -1)
				.sort((a, b) => {
					const atitle = vue.keys[a].title;
					const btitle = vue.keys[b].title;
					return atitle?.localeCompare(btitle);
				}),
			false
		),
	];

	if (editable) {
		elements.push(
			<HeaderButtons
				userId={userId}
				id={id}
				form={form}
				keys={[]}
				state={state}
				setState={setState}
				total={data.count}
				size={size}
				current={page}
				cols={upperColsRef.current ?? []}
				descs={vue.keys}
				arrays={vue.rawIdx?.updating.arrays ?? {}}
				requiredValues={requiredValues}
				title={vue.rawIdx?.title ?? "import"}
			/>
		);
	}

	// #region MoreColumns
	if (!editable && vue?.params && !vue?.params.hideCol && showCols) {
		elements.push(
			<MoreColumns
				key={_uniqueId("more-cols-")}
				default={defaultChecked}
				current={keys}
				onChange={setKeys}
				titlesMap={titlesMap}
				color={color}
				ignored={vue.rawView?.filtered_columns ?? []}
				lang={lang}
			/>
		);
	}
	// #endregion

	const onChange = genOnChange(search, updateSearch);

	// #region filters
	function filterInjector(e: any) {
		const { _type, _data_type } = e;
		if (!_type || !_data_type) {
			return e;
		}
		if (_type === "date" || _data_type === "date") {
			return {
				...e,
				sorter: true,
				filterDropdown: (props: any) => (
					<DatePicker
						{...props}
						e={e}
						search={search}
						updateSearch={updateSearch}
					/>
				),
			};
		}
		if (_data_type === "complete" && _type !== "tags") {
			return {
				...e,
				sorter: true,
				filterDropdown: (props: any) => (
					<FullText
						{...props}
						e={e}
						search={search}
						updateSearch={updateSearch}
						suggestions={suggestions}
						updateSuggestions={updateSuggestions}
						lang={lang}
					/>
				),
			};
		}
		if (_type === "tags") {
			return {
				...e,
				sorter: true,
				filterDropdown: (props: any) => (
					<Tags
						{...props}
						e={e}
						search={search}
						updateSearch={updateSearch}
						vue={vue}
						lang={lang}
					/>
				),
			};
		}
		return e;
	}
	// #endregion

	// #region TimeSeries
	function applyTimeSerie(serie: TimeSerie) {
		const { columns } = serie;
		let { format, value } = serie;
		if (!columns) {
			return [];
		}
		format = format ?? "last";
		value = value ?? [6];
		let timeKeys: string[];
		switch (format) {
			case "last": {
				timeKeys = columns.slice(
					columns.length - 1 - value[0],
					columns.length - 1
				);
				break;
			}
			case "first": {
				timeKeys = columns.slice(0, value[0]);
				break;
			}
			default:
				timeKeys = [];
		}
		return timeKeys;
	}

	const { series } = vue.params;

	function colsFromSeries(series: Map<Serie>): Map<string[]> {
		return Object.keys(series).reduce((acc, cur) => {
			const serie = series[cur];
			const s = serie.type === "time" ? applyTimeSerie(serie as TimeSerie) : [];
			return { ...acc, [cur]: s };
		}, {} as Map<string[]>);
	}
	// #endregion

	// let dataSource = data.hits;

	// #region columns
	const itmCols = cols.map(Columns).map(filterInjector);
	let columns = itmCols;
	if (series) {
		const additionalCols = colsFromSeries(series);
		const moreCols = Object.keys(additionalCols).reduce((acc, key) => {
			const values: Maybe<string[]> = additionalCols[key];
			const valCols = values.map((k) => ({
				title: moment(k).format("MMM YYYY"),
				dataIndex: k,
				render: function render(_: any, record: any) {
					const value = record[key].find((e: any) => e.key === k);
					const v = value.value;
					return v >= 0 ? v : "n/a";
				},
			}));
			return [...acc].concat(valCols);
		}, [] as any[]);
		columns = [...columns, ...moreCols];
	}

	columns = columns.map((c) => ({
		...c,
		filterIcon: (_: boolean) => {
			const key = c._dataIndex;
			const v = search.params ? search.params[key] : undefined;
			const filtered = !!v && v.length && v.length > 0;
			return (
				<SearchOutlined
					style={{
						color: filtered ? getDomainColor(false) : "#bfbfbf",
						width: 12,
					}}
				/>
			);
		},
	}));
	// #endregion

	// #region edit
	function setEditingKey(newEditingKey: React.Key) {
		return setState({
			editedData: editedData,
			editingKey: newEditingKey,
			saving: false,
		});
	}

	function setData(newEditedData: Item[]) {
		return setState({
			editingKey: editingKey,
			editedData: newEditedData,
			saving: false,
		});
	}

	async function save(key: React.Key) {
		try {
			const row = {
				...((await form.validateFields()) as Item),
				_edited: moment.parseZone(undefined),
			};

			const newData = [...editedData];
			const index = newData.findIndex((item) => key === item.key);
			if (index > -1) {
				const item = merge(row, newData[index]) as Item;
				Object.keys(item)
					.filter((k) => k.indexOf(".") !== -1)
					.forEach((k) => delete item[k]);
				newData.splice(index, 1, item);
			} else {
				newData.push(row);
			}
			setState({
				editedData: newData,
				editingKey: "",
				saving: false,
			});
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	}

	function cancel() {
		if (!oldValue) {
			const index = editedData.findIndex((e) => e.key === editingKey);
			if (index === -1) {
				setEditingKey("");
				return;
			}

			const newData = [...editedData];
			newData.splice(index, 1);
			setState({
				editingKey: "",
				editedData: newData,
				oldValue: undefined,
				saving: false,
			});
			return;
		}

		const obj = form.getFieldsValue();
		// !TODO: Fetch these somewhere if idx is not fetched and editable
		const keyDescriptions: KeyDescription[] = [];
		// Assert whether all IMPORTANT field have at least a viable value
		const ok = Object.keys(obj).reduce((acc, cur) => {
			const e = keyDescriptions.find((k) => k.key === cur);
			if (!e?.priority || e.priority !== PRIO_REQUIRED) return acc;
			return acc && !!obj[cur];
		}, true);
		// If any IMPORTANT field is undefined, this is a newly created object
		// to remove from the list as we cancel its edition
		if (!ok) {
			const index = editedData.findIndex((e) => e.key === editingKey);
			if (index !== -1) {
				const newData = [...editedData];
				newData.splice(index, 1);
				setState({
					editedData: newData,
					editingKey: "",
					saving: false,
				});
				return;
			}
		}
		setEditingKey("");
	}

	function edit(
		record: Partial<Item> & { key: React.Key } & { __status: EditionStatus }
	) {
		form.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setState({
			editedData: editedData,
			editingKey: record.key,
			oldValue: { ...record },
			saving: false,
		});
	}

	function dup(item: Item) {
		const { key } = item;
		const index = editedData.findIndex((i) => key === i.key);
		if (index === -1) {
			return;
		}
		const newData = [...editedData];
		const ukey = _uniqueId("data-");
		const { _created, _edited, _deleted, ...restProps } = item;

		// !TODO: unspecialize it
		delete item.contract.uid;
		newData.splice(index + 1, 0, { ...restProps, key: ukey });
		setData(newData);
	}

	function remove(item: Item) {
		const { key } = item;
		const index = editedData.findIndex((i) => key === i.key);
		if (index === -1) {
			return;
		}
		const { _created } = item;
		if (_created === undefined) {
			const newData = [...editedData];
			newData.splice(index, 1);
			setData(newData);
			return;
		}

		const newItem = {
			...item,
			_deleted: moment.parseZone(undefined),
		};
		const newData = [...editedData];
		newData.splice(index, 1, newItem);
		setData(newData);
	}

	function restore(item: Item) {
		const { key } = item;
		const index = editedData.findIndex((i) => key === i.key);
		if (index === -1) {
			return;
		}

		const { _deleted, ...newItem } = item;
		const newData = [...editedData];
		newData.splice(index, 1, newItem);
		setData(newData);
	}

	function isEditing(i: Item): boolean {
		return i.key === editingKey;
	}

	if (editable) {
		columns = addEditActionsCol(
			id,
			columns,
			get(vue.rawView?.idx) ?? [],
			editingKey,
			isEditing,
			save,
			cancel,
			edit,
			dup,
			remove,
			restore,
		);
	}

	const defRange: { of: string; elements: string } = {
		of: "de",
		elements: "éléments",
	}

	const rangeParts: { [lang: string]: { of: string; elements: string } } = {
		fr: defRange,
		en: {
			of: "of",
			elements: "elements"
		}
	}

	// #region Pagination
	const paginationProps = {
		pageSize: size,
		current: page,
		total: data.count,
		onChange(page: number, size?: number) {
			updateSearch(paginationLens.set(search, newPagination(page, size)));
		},
		showTotal(total: number, range: [number, number]): React.ReactNode {
			const { of, elements } = (rangeParts[lang] ?? defRange);
			return total > size
				? `${range[0]}-${range[1]} ${of} ${total} ${elements}`
				: `Total: ${total} ${elements}`;
		},
	};
	// #endregion
	const dataSource = editable ? editedData : data.hits;
	if (editable) {
		upperColsRef.current = columns?.map(c => ({
			dataIndex: c?._dataIndex,
			title: c?.title,
		}));
	}

	const tableComponents = editable
		? { body: { cell: EditableCell } }
		: undefined;
	const showSorterTooltip = {
		title: lang === "fr" ? "Cliquer pour trier" : undefined,
		getPopupContainer: getSorterContainer,
	};
	const tablePagination = editable
		? false
		: {
			...paginationProps,
			position: ["topRight" as const, "bottomRight" as const],
		};
	const titles = Object.keys(vue.keys).reduce<Map<Maybe<string>>>(
		(prev, curr) => ({ ...prev, [curr]: vue?.keys[curr]?.title }),
		{}
	);
	return (
		<div style={{ minWidth: 650 }}>
			<ViewInfo hideInfo={hideInfo} vue={vue} />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				{elements}
			</div>
			<div style={{ display: "flex", marginBottom: "6px" }}>
				<SearchDisplayer
					search={search.params ?? defaultSearchParams}
					updateSearch={(s) => updateSearch(paramsLens.set(search, s))}
					titles={titles}
				/>
			</div>
			<div className="gedata-tooltip-container" />
			<div className="gedata-sorter-container" />
			<MaybeForm form={form}>
				<Table
					components={tableComponents}
					showSorterTooltip={showSorterTooltip}
					locale={lang !== "fr" ? { emptyText: "Aucune donnée" } : undefined}
					size="small"
					onChange={onChange}
					getPopupContainer={getTooltipContainer}
					loading={loading || saving || false}
					columns={columns}
					dataSource={dataSource}
					scroll={{
						x: columns.length > 9 ? "200%" : "scroll",
						y: "70vh",
					}}
					sticky
					pagination={tablePagination}
					showHeader
					summary={calcSummary(vue, columns.length)}
					rowClassName={getRowClassname}
				/>
			</MaybeForm>
		</div>
	);
}
WidgetTable.defaultProps = defaultWidgetTableProps;

export default WidgetTable;
