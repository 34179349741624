
import { message } from "antd";

import { Map, Maybe } from "../../interfaces/Utils";
import { MinimalInfoState } from "../../store/infographics";
import { loginFailedAction } from "../../store/login";
import { MinimalLoginState } from "../../store/login/interface";
import { WidgetState } from "../../store/widget";
import { labelsMap, conversions, } from '../Indicators/json/ftb.json';
import { ROOT_API_URL, newHeaders, withAuthorization } from "../../lib/fetch";
import { SaveView, View } from "../../model/vue";
import { Store } from "@reduxjs/toolkit";
import { History } from "history";
import React from "react";
import { TimedResponse } from "../../store/widget/interfaces";
import { Sorting } from "../../model/search";
import moment from "moment";
import { frenchFormat } from "../../interfaces/editorial";

function paramsReducer(rawParams: Map<string[]>) {
    return (acc: Map<string[]>, cur: string) => {
        const v = rawParams[cur];
        return (!v || v.length === 0)
            ? acc
            : { ...acc, [cur]: v };
    };
}

function labelFilter(v: string) {
    return !(v.match(/^[A-Z]{3}_/) && !v.startsWith("IDX_"));
}

function checkedReducer(acc: string[], cur: React.Key) {
    const v = '' + cur;
    const splt = v.split('.');
    if (splt.length != 3)
        return acc;
    const [_, labeled, toConvert] = splt;
    const labels = labeled.split(',').filter(labelFilter).sort().join('.');
    const outer = (labelsMap as Map<string>)[labels];
    if (!outer)
        return acc;
    const key = (conversions as Map<string>)[outer + '.' + toConvert];
    if (!key)
        return acc;

    return [...acc, key];
};

async function addToProject(
    projectId: string,
    userId: string,
    id: string,
    setLoading: (v: boolean) => void,
    h: History<any>,
) {
    const url = new URL(`${ROOT_API_URL}/api/v1/fetch/project`);
    const method = "PATCH";
    const body = JSON.stringify({
        id: projectId,
        params: {
            used_views: {
                action: "append",
                value: id,
            }
        }
    });
    const headers = withAuthorization(userId, newHeaders());
    if (setLoading) {
        setLoading(true);
    }

    const r = await fetch(url.href, { headers: headers, method: method, body: body });
    if (!r.ok) {
        message.error(`Erreur (${r.status}): erreur lors de l'ajout de la vue`);
        if (setLoading) {
            setLoading(false);
        }
    } else {
        message.success("Votre infographie a bien été créée, vous allez être redirigé au projet");
        setTimeout(() => {
            h.push(`/visualisations/projects/edit/${projectId}`);
        }, 1000);
    }
}

async function trySaveView(
    projectId: Maybe<string>,
    store: Store<(MinimalInfoState & { widget: WidgetState } & MinimalLoginState)>,
    setLoading: (v: boolean) => void,
    h: History<any>,
) {
    const { dispatch, getState } = store;
    if (!projectId) {
        message.error("Il manque l'ID du projet.");
        return;
    }
    const { info, widget, login: { userId, } } = getState();
    const title = info.desc.info?.private.title;
    const type = info.desc.type;
    if (!title || title === "") {
        message.error("Il manque le nom de la nouvelle infographie");
        return;
    } else if (!userId) {
        dispatch(loginFailedAction(401));
        return;
    }

    if (!type) {
        message.error("Cette vue n'a pas de type de représentation.")
        return;
    }

    setLoading(true);
    message.success("Votre infographie va etre sauvegardée.");

    const {
        news_tank, info: viewInfo, meta, filtered_columns, default_columns,
        sorting,
    } = info.desc;
    const rawParams: Map<string[]> = (widget.search?.params || {});
    const view = info.vueTriple?.vue as unknown as View;
    const parentUid = view?.uid;
    if (!parentUid) {
        message.error("500: Missing parent UID");
        return;
    }
    const params = Object
        .keys(rawParams)
        .reduce<Map<string[]>>(paramsReducer(rawParams), {});
    const newSorting: Maybe<Sorting> = (sorting && sorting.key !== '')
        ? sorting
        : undefined;
    console.log(info.desc.static);
    const saveView: SaveView = {
        uid: info.desc.uid,
        parent_uid: parentUid,
        type: type,
        news_tank: news_tank,
        filtered_columns: filtered_columns,
        default_columns: default_columns,
        search_params: params,
        sorting: newSorting,
        idx: view.idx,
        info: {
            ...viewInfo,
            private: {
                ...(viewInfo?.private ?? {}),
                title: title,
            }
        },
        meta: meta !== undefined
            ? {
                ...meta,
                sources: meta?.sources,
            }
            : undefined,
        dates: info.desc.dates,
        static: info.desc.static !== undefined
            ? (typeof (info.desc.static) === "string"
                ? info.desc.static
                : info.desc.static.format(frenchFormat)
            )
            : undefined,
        // dynamic: info.desc.dynamic,
        filters: info.filter?.clauses,
    };

    const body = JSON.stringify(saveView);

    const headers = withAuthorization(userId, newHeaders());
    const url = new URL(ROOT_API_URL + "/api/v1/fetch/view");
    const r = await fetch(url.href, { headers, method: "POST", body, });
    if (!r.ok) {
        if (r.status === 401) {
            dispatch(loginFailedAction(401));
            return;
        } else {
            message.error(`ERROR: ${r.status}: ${r.statusText}`);
            return;
        }
    }

    const { data: { id } }: TimedResponse<{ id: string; }, never> = await r.json();
    if (id !== info.desc.uid) {
        await addToProject(projectId, userId, id, setLoading, h);
    } else {
        setLoading(false);
        message.success("Votre infographie a bien été mise à jour, vous allez être redirigé");
        setTimeout(() => h.push(`/visualisations/projects/edit/${projectId}`), 1000);
    }
}

export default trySaveView;
