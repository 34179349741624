import { Typography } from "antd";
import { dedup } from "../../../../lib/functions";

import getDomainColor from "../../../tools/getDomainColor";
import { ColumnProps } from "../columns";
import { TagType } from "./tags";

const { Paragraph } = Typography;

export function getAllValues(v: any, keys: string[]): TagType {
	if (keys.length < 1) {
		return [];
	} else if (keys.length === 1) {
		return v?.[keys[0]];
	}
	const lkeys = [...keys];
	const rem = lkeys.splice(1);
	const key = lkeys[0];
	const value = v[key];
	if (Array.isArray(value)) {
		return value.flatMap((lv) => getAllValues(lv, rem));
	}
	if (rem.length === 1) {
		return [value[rem[0]]];
	}
	return getAllValues(value, rem);
}

export default function arrayStringHandler(props: ColumnProps, i: number) {
	const { title, dataIndex, type, data_type } = props;

	const k = dataIndex.split(".");
	if (k.length <= 0) {
		console.log("ERROR:", "INVALID DATA INDEX FOR", title, dataIndex, type);
	}
	const rem = k.slice(1);

	return {
		title: title,
		dataIndex: k[0],
		_dataIndex: dataIndex,
		_type: type,
		_data_type: data_type,
		key: i,
		render: function render(value: any) {
			const values = getAllValues(value, rem);
			return (
				<Paragraph
					className="table-paragraph"
					ellipsis={{
						expandable: true,
						rows: 3,
						symbol: (
							<span style={{ color: getDomainColor(false) }}>plus</span>
						),
					}}
				>
					{Array.isArray(values) ? dedup(values).join(", ") : values}
				</Paragraph>
			);
		},
	};
}
