import { labelsMap, conversions, } from '../../Indicators/json/ftb.json';
import { Map, Maybe } from "../../../interfaces/Utils";
import { dataTypeMasks } from "./Filters";
import { ColumnProps, Visibility } from './interfaces';
import { InfoState } from '../../../store/infographics';
import React from 'react';
import { DeepObject } from '../../../lib/array';

export const visibilities: Visibility[] = ["default", "hidden", "removed"];

function matchLabel(s: string): boolean {
	return !(s.match(/^[A-Z]{3}_/) && !s.startsWith("IDX_"));
}

function getVisibility(defCols: Maybe<string[]>, filtered: string[]): (title: string) => Visibility {
	return function (title: string) {
		if (defCols && defCols?.findIndex(e => e === title) !== -1) {
			return 'default';
		} else if (filtered.indexOf(title) !== -1) {
			return 'removed';
		}
		return 'hidden';
	}
}


export function processValue<T>(
	value: React.Key,
	filterMap: (title: string, value: string, maskKey: string, visibility?: Visibility) => Maybe<T>,
	visibility?: Visibility,
): Maybe<T> {
	const s = '' + value;
	const splt = s.split(".");
	if (splt.length != 3) {
		return undefined;
	}
	const [_, label, key] = splt;
	if (key.endsWith("uid")) {
		return undefined;
	}
	const rl = label
		.split(",")
		.filter(matchLabel)
		.sort()
		.join('.');
	const rn = (labelsMap as Map<string>)[rl];
	if (rn) {
		const rl = `${rn}.${key}`;
		const rk = (conversions as Map<string>)[rl];
		if (rk) {
			const title = dataTypeMasks[rk];
			if (title) {
				return filterMap(title, s, rk, visibility);
			}
		}
	}
	return undefined;
}


export function getCols(
	cols: Maybe<string[]>,
	info: Maybe<InfoState>,
): ColumnProps[] {
	if (!cols) {
		return [];
	}
	const checked = info?.checked ?? [];
	const filtered = info?.desc.filtered_columns ?? [];
	const default_ = info?.desc.default_columns ?? [];

	const fGetVis = getVisibility(cols, filtered);
	function updateValue(title: string, value: string, maskKey: string, visibility?: Visibility) {
		return {
			title: title,
			value: value,
			maskKey: maskKey,
			visibility: visibility ?? fGetVis(maskKey),
		};
	}

	function reducer(acc: ColumnProps[], cur: React.Key) {
		const value = processValue(cur, updateValue);
		return value ? [...acc, value] : acc;
	}

	const temp = checked.reduce(reducer, []);
	const sortedtemp = temp.sort((a, b) => {
		const apos = visibilities.indexOf( a.visibility);
		const bpos = visibilities.indexOf( b.visibility);
		return apos === bpos
			? a.title.localeCompare(b.title)
			: apos - bpos;
	});
	const dflts = sortedtemp.splice(0, default_.length);
	const n = dflts.reduce((acc, cur) => {
		const n = [...acc];
		n[default_.indexOf(cur.maskKey)] = cur;
		return n;
	}, new Array<ColumnProps>(dflts.length));
	return [...n, ...sortedtemp];
}

export function sortCols(cols: ColumnProps[]): [string[], string[]] {
	const default_: string[] = [];
	const filtered: string[] = [];
	for (let i = 0; i < cols.length; i++) {
		const c = cols[i];
		const { visibility, maskKey } = c;
		switch (visibility) {
			case 'default':
				default_.push(maskKey);
				break;
			case 'removed':
				filtered.push(maskKey);
				break;
		}
	}
	return [default_, filtered];
}