import { Map } from "../../interfaces/Utils";

export const labels: Map<string> = {
	Contract: "Contrats",
	Sponsor: "Partenaires",
	Sector: "Secteurs d'activité",
	Championship: "Championnats",
	"Organization - Championship": "Championnats",
	Organization: "Organisations",
	Country: "Pays",
	Season: "Saisons de football",
	Type: "Types de contrat",
};

export const keys: Map<string> = {
	"organization.name": "Organisation",
	"sponsor.name": "Partenaire",
	"contract.category.name.fr": "Type de contract",
	"contract.visibility.fr": "Visibilité",
	"contract.end": "Date de fin",
};
