import { connect, ConnectedProps } from "react-redux";

import { Result as AntResult } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";

import { RootState } from "../../../store";
import { ComponentName } from "../../../store/sidebar";
import NodeEdit from "../../Indicators/Label/NodeEdit";
import NodeMerge from "../../Indicators/Label/NodeMerge";
import PropEdit from "./PropEdit";

// #region Store connection
interface StateProps {
	rightSider?: boolean;
	component?: ComponentName;
	data?: any;
}

const mapStateToProps = ({
	sidebar: { collapsed, component, data },
}: RootState): StateProps => ({
	rightSider: collapsed,
	component: component,
	data: data,
});
const connector = connect(mapStateToProps);
// #endregion

type SidebarProps = ConnectedProps<typeof connector>;

function selectComponent(component?: string, data?: any): React.ReactNode {
	switch (component) {
		case "prop-edit":
			return <PropEdit data={data} />;
		case "node-edit":
			return <NodeEdit data={data} />;
		case "node-merge":
			return <NodeMerge data={data} />;
		default:
			return (
				<AntResult
					icon={<SmileOutlined />}
					title="Vos données sont en cours de chargement."
				/>
			);
	}
}

function Sidebar(props: SidebarProps) {
	const { rightSider, component, data } = props;

	return (
		<Sider
			className="AppLayout-right-sider"
			width={368}
			theme="light"
			defaultCollapsed
			collapsible
			collapsedWidth={0}
			trigger={null}
			collapsed={rightSider}
		>
			{selectComponent(component, data)}
		</Sider>
	);
}

export default connector(Sidebar);

export { PropEdit };
